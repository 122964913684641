import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingService } from '@foxeet/utils/services/loading.service';
import { FormUtils } from '@foxeet/utils/functions';

@Injectable({
  providedIn: 'root',
})
export class LoadingInterceptorService implements HttpInterceptor {
  constructor(private _loadingService: LoadingService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const excludeLoading = FormUtils.transformBooleanValue(request.params.get('excludeLoading'));
    if (!excludeLoading) this._loadingService.present();
    return next.handle(request).pipe(
      finalize(() => {
        if (!excludeLoading) this._loadingService.dismiss();
      }),
    );
  }
}
