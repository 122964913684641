export enum ActionsContainerButtonTypes {
  default = 0,
  warning = 1,
}

export interface ActionsContainerButton {
  actionId: string;
  type: ActionsContainerButtonTypes;
  icon: string;
  callback: any;
  enable?: boolean;
  class?: string;
  extraParams?: any;
}

export interface ActionsContainerModel {
  actions: ActionsContainerButton[];
}
