<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()" class="button-icon-clear-rounded-contrast"><ion-icon name="close-outline" color="secondary"></ion-icon></ion-button>
    </ion-buttons>
    <ion-title>{{ 'config' | translate | uppercase }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid *ngIf="form">
    <ion-row>
      <ion-col class="no-padding">
        <ion-segment mode="md" [value]="segmentActive" (ionChange)="changeSegment($event)">
          <ion-segment-button *ngIf="languages?.length" value="language" id="AT_language_btn">
            <ion-label>{{ 'language' | translate }}</ion-label>
          </ion-segment-button>

          <ion-segment-button *ngIf="currencies?.length" value="currency" id="AT_currency_btn">
            <ion-label>{{ 'currency' | translate }}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="languages?.length && segmentActive === 'language'" class="wrapper">
      <ion-col size-xs="12" size-sm="3" *ngFor="let item of languages; index" (click)="setLanguage(item)" id="AT_language_option_{{ index }}">
        <div [ngClass]="{ selected: selectedLanguagesComparation(item) }">
          <p>{{ item.translateKey | translate }}</p>
        </div>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="currencies?.length && segmentActive === 'currency'" class="wrapper">
      <ion-col size="12" *ngIf="showWarning" class="error"> {{ 'select_currency_warning' | translate }} </ion-col>
      <ion-col size-xs="12" size-sm="4" *ngFor="let item of currencies" (click)="setCurrency(item)" id="AT_currency_option_{{ index }}">
        <div [ngClass]="{ selected: item.cultureCodeCurrency === form.get('cultureCodeCurrency').value }">
          <p class="text-default-bold">{{ item.value }}</p>
          <p>{{ item.symbol }} - {{ item.code }}</p>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-row>
    <ion-col>
      <ion-button color="light" (click)="closeModal()" id="AT_close_modal_btn"> {{ 'ACTIONS_cancel' | translate }} </ion-button>
    </ion-col>
    <ion-col>
      <ion-button (click)="save()" [disabled]="form.invalid" id="AT_save_modal_btn"> {{ 'ACTIONS_save' | translate }} </ion-button>
    </ion-col>
  </ion-row>
</ion-footer>
