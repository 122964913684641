import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UnsuscriptionHandler } from '@foxeet/utils/components';
import { takeUntil, filter } from 'rxjs/operators';
import { NavigationBar } from './domain/navigation-bar.model';

@Component({
  selector: 'mcore-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
})
export class NavigationBarComponent extends UnsuscriptionHandler implements OnInit {
  @Input() navigationConfig: NavigationBar;

  public selectedPath = '';

  constructor(private _router: Router) {
    super();
    this._router.events
      .pipe(
        takeUntil(this._componentDestroyed),
        filter((event) => event instanceof NavigationEnd),
      )
      .subscribe(() => this.setSelectedPath());
  }

  ngOnInit() {
    this.setSelectedPath();
  }

  public setSelectedPath() {
    const path = window.location.pathname.split('/').pop();
    this.selectedPath = path ? path : this.navigationConfig.urlActive;
  }

  onChangeSegment(route: string) {
    const routes = [this.navigationConfig.urlBase];
    if (route?.length) routes.push(route);

    this._router.navigate([routes.join('/')]);
  }
}
