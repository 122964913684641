<ion-header>
  <ion-toolbar>
    <ion-searchbar
      *ngIf="!config?.hideFilterBar"
      (ionInput)="onChangeSearchBar($event)"
      debounce="300"
      enterkeyhint="enter"
      spellcheck="true"
      [placeholder]="'search' | translate"
    ></ion-searchbar>
    <ion-buttons slot="end">
      <ion-button class="button-icon-clear-rounded" (click)="closeModal()">
        <ion-icon name="close" size="small"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [scrollY]="false">
  <cdk-virtual-scroll-viewport itemSize="45" minBufferPx="270" maxBufferPx="405">
    <ion-list>
      <ion-item *cdkVirtualFor="let item of filteredItems; trackBy: trackByMethod; let i = index" [disabled]="item.disabled" id="AT_item_{{ i }}">
        <ion-checkbox
          labelPlacement="end"
          justify="start"
          [value]="item[config?.value]"
          [checked]="item.isChecked"
          [disabled]="item.expertOnly"
          id="AT_item_checkbox_{{ i }}"
          (ionChange)="onChangeItemSelected($event)"
          >{{ item[config?.label] | translate }}</ion-checkbox
        >
        <ion-chip *ngIf="item.expertOnly" id="AT_item_expert_only_{{ i }}" class="expert-only-chip" color="primary" [outline]="true">
          <ion-label>Comfort/Premium</ion-label>
        </ion-chip>
      </ion-item>

      <ion-item *ngIf="!filteredItems.length">
        <ion-label class="info">{{ 'noContent' | translate }}</ion-label>
      </ion-item>
    </ion-list>
  </cdk-virtual-scroll-viewport>
</ion-content>
<ion-footer *ngIf="config?.allowMultipleSelection">
  <ion-toolbar>
    <ion-buttons>
      <ion-button class="secondary" id="AT_close_modal_btn" (click)="closeModal()">{{ 'ACTIONS_cancel' | translate | uppercase }} </ion-button>
      <ion-button class="primary" id="AT_confirm_modal_btn" (click)="confirm()">
        {{ 'ACTIONS_confirm' | translate | uppercase }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
