import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasicContainerComponent } from './basic-container.component';
import { IonicModule } from '@ionic/angular';
/**
 * @TODO circular dependency
 */
import { I18nModule } from '@foxeet/utils-modules';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [BasicContainerComponent],
  imports: [CommonModule, IonicModule, I18nModule.forChild(), MatTooltipModule],
  exports: [BasicContainerComponent],
})
export class BasicContainerModule {}
