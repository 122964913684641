import { Component, Input, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'mcore-menu-header',
  templateUrl: './menu-header.component.html',
  styleUrls: ['./menu-header.component.scss'],
})
export class MenuHeaderComponent {
  @Input() logo: string;
  @Input() appVersion: string;

  public isIOS = false;

  constructor(private _platform: Platform) {
    this.isIOS = this._platform.is('iphone');
  }
}
