import { AssetSurfaceIds } from '../enums';
import { FoxeetKeyValueModel } from './extra-information.model';
import { BuildingElementItemResidualModel, TerrainExpenseDto, TerrainIncomeDto } from './valoration-dynamic-residual-method.model';

export interface ValorationStaticResidualMethodModel {
  id: number;
  tempId: string;
  massiveValorationTempId: string;
  assetId: number;
  clusterId: number;
  buildingElementId: number;
  totalAdoptedSurface: number;
  assetSurfaceId: AssetSurfaceIds;
  depreciationFunctionalAdoptedTotalValue: number;
  depreciationFunctionalAdoptedUnitValue: number;
  groundRepercussionAdoptedUnitValue: number;
  groundRepercussionAdoptedTotalValue: number;
  observations: string;
  buildeable: number;
  numberOfElementsToBuild: number;
  isFutureValoration: boolean;
  buildingElements: BuildingElementItemResidualModel[];
  terrainIncomesObservations: string;
  terrainExpensesObservations: string;
  buildingElementsObservations: string;
  terrainIncomes: TerrainIncomeDto[];
  terrainExpenses: TerrainExpenseDto[];
  totalNetIncome: number;
  totalNetExpense: number;
  totalIncomeExpenseDiff: number;
  staticResidualTotalValue: number;
  staticResidualUnitValue: number;
  isMigrationValoration: boolean;
  keyValues: FoxeetKeyValueModel[];
}
