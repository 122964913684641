import { ScrollingModule } from '@angular/cdk/scrolling';
import { Injector, NgModule } from '@angular/core';
import { dynamicInjector } from '@foxeet/utils/functions';
import { I18nModule } from '@foxeet/utils-modules';
import { IonicModule } from '@ionic/angular';
import { MCoreModule } from './mcore.module';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule, I18nModule, MCoreModule, ScrollingModule, IonicModule],
  declarations: [],
  exports: [MCoreModule, IonicModule],
})
export class MobileCoreModule {
  static injector: Injector = null;
  constructor(private injector: Injector) {
    MobileCoreModule.injector = this.injector;
    dynamicInjector(injector);
  }
}
