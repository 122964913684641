<form class="form" [formGroup]="form">
  <ion-row>
    <ion-col size="12">
      <ui-mobile-form-input-password [label]="'LOGIN_password' | translate" [form]="form" [controlName]="'password'"></ui-mobile-form-input-password>
    </ion-col>
    <ion-col size="12">
      <ui-mobile-form-input-password [label]="'LOGIN_confirmPassword' | translate" [form]="form" [controlName]="'confirmPassword'"></ui-mobile-form-input-password>
    </ion-col>
    <ion-col size="12" *ngIf="form.invalid">
      <p>{{ 'FORM_VALIDATION_NOT_SAME' | translate | capitalize }}</p>
    </ion-col>
  </ion-row>
</form>
<ion-row class="ion-justify-content-center">
  <ion-col size-xs="8" size-sm="6" size-md="5" size-lg="5">
    <ion-button [disabled]="form.invalid" (click)="submit()">{{ 'LOGIN_change' | translate | uppercase }}</ion-button>
  </ion-col>
</ion-row>
