import { Injectable } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { Capacitor } from '@capacitor/core';
import {
  ActionPerformed as PushNotificationActionPerformed,
  PushNotifications,
  PushNotificationSchema as PushNotification,
  Token as PushNotificationToken,
} from '@capacitor/push-notifications';
import { LocalNotifications, LocalNotificationSchema as LocalNotification } from '@capacitor/local-notifications';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  public firebaseToken!: string;
  public tokenEnable: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(public _platform: Platform, private alertController: AlertController) {}

  initPush() {
    if (Capacitor.getPlatform() !== 'web') {
      this.registerPush();
    }
  }

  private async registerPush() {
    let permStatus = await PushNotifications.checkPermissions();
    // PushNotifications.requestPermissions().then((permission) => {
    //   if (permission.receive === 'granted') {
    //     // Register with Apple / Google to receive push via APNS/FCM
    //     PushNotifications.register();
    //   } else {
    //     // No permission for push granted
    //   }
    // });

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }

    await PushNotifications.register();

    PushNotifications.addListener('registration', (token: PushNotificationToken) => {
      console.log('My token: ' + JSON.stringify(token));
      this.tokenEnable.next(token.value);
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error: ' + JSON.stringify(error));
    });

    PushNotifications.addListener('pushNotificationReceived', async (notification: PushNotification) => {
      console.log(notification);
      NotificationsService._doLocalNotif(notification);
    });

    PushNotifications.addListener('pushNotificationActionPerformed', async (notification: PushNotificationActionPerformed) => {
      const data = notification.notification.data;
      console.log('Action performed: ' + JSON.stringify(notification.notification));
      if (data.detailsId) {
        // this.router.navigateByUrl(`/home/${data.detailsId}`);
      }
    });
  }

  private static _doLocalNotif(notification: PushNotification) {
    const localNotification: LocalNotification = {
      title: notification.title ?? '',
      body: notification.body ?? '',
      id: Date.now(),
      actionTypeId: '',
      smallIcon: 'notification_icon',
      //TODO use variable
      iconColor: '#12988f',
    };

    LocalNotifications.schedule({
      notifications: [localNotification],
    });
  }

  // redirection(data) {
  //   if (data.AppraisalId) {
  //     let section = '';
  //     const params: any = {};
  //     switch (parseInt(data.NotificationType, 10)) {
  //       case PushNotificationType.Appraisal:
  //         section = 'info';
  //         break;
  //       case PushNotificationType.Incidents:
  //         section = 'incidents';
  //         params.tab = ApplicationTabTypes.Incidents;
  //         break;
  //       case PushNotificationType.Revisions:
  //         section = 'incidents';
  //         params.tab = ApplicationTabTypes.Revisions;
  //         break;

  //       case PushNotificationType.Comments:
  //         section = 'board';
  //         break;

  //       default:
  //         break;
  //     }
  //     this.route.navigate(['appraisal', data.AppraisalId, section], {queryParams: {...params}});
  //   }
  // }
}
