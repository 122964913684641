import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@foxeet/feature/auth';
import { PASSWORD } from '@foxeet/utils/forms';
import { checkIfFieldsAreEqual, customRegexValidator } from '@foxeet/utils/validators';
import { ToastService } from '@foxeet/utils/services/toast.service';

@Component({
  selector: 'mcore-password-restore',
  templateUrl: './password-restore.component.html',
  styleUrls: ['./password-restore.component.scss'],
})
export class PasswordRestoreComponent implements OnInit {
  public form: FormGroup;

  private _paramCode: string;
  private _paramEmail: string;

  constructor(private _authService: AuthService, private _route: ActivatedRoute, private _router: Router, private _toastService: ToastService) {
    this._initForm();
  }

  ngOnInit() {
    this._initParams();
  }

  public submit() {
    if (this.form.valid) {
      this._authService.restoreThePassword(this._paramEmail, this._paramCode, this.form.get('password').value, this.form.get('confirmPassword').value).subscribe(
        (result) => {
          if (result.ok) {
            this._router.navigate(['/login']);
          }
        },
        (e) => {
          this._toastService.error(e.error.title, e.error.detail);
        },
      );
    }
  }

  private _initParams() {
    this._route.queryParamMap.subscribe((queryParams) => {
      this._paramCode = queryParams.get('code');
      this._paramEmail = queryParams.get('email');
    });
  }

  private _initForm(): void {
    this.form = new FormGroup(
      {
        password: new FormControl(null, [Validators.required, customRegexValidator(PASSWORD.pattern, PASSWORD.errorKey)]),
        confirmPassword: new FormControl(null, Validators.required),
      },
      checkIfFieldsAreEqual('password', 'confirmPassword'),
    );
  }
}
