import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UsersService } from '@foxeet/data-access';
import { AppraisalIndexModel, EventType } from '@foxeet/domain';
import { UnsuscriptionHandler } from '@foxeet/utils/components';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AppraiserAgendaDetailComponent } from '../appraiser-agenda-detail/appraiser-agenda-detail.component';
import { MobileCalendarComponent } from '../calendar/calendar.component';
import { AuthService } from '@foxeet/feature/auth';

export interface AppraisalsEventsByDay {
  VisitSchedulded: AppraisalIndexModel[];
  Deadlines: AppraisalIndexModel[];
}

export interface AgendaModalDataModel {
  Today: DateModel;
  VisitScheduled: AppraisalIndexModel[];
  Deadlines: AppraisalIndexModel[];
}

export interface DateModel {
  Day: number;
  Month: number;
  MonthName?: string;
  Year: number;
}

export interface AppraiserUserEventFilterModel {
  minDateTime: any;
  maxDateTime: any;
  appraiserUserId?: number;
}

export interface AppraiserUserSummaryEventsByDay {
  eventDateTime: any;
  events: EventsByDay[];
}
export interface EventsByDay {
  absenceFinishDatetime: Date;
  absenceStartDatetime: Date;
  appraisalId: number;
  eventDateTime: Date;
  eventType: EventType;
}

export interface AppraisalsEventsByDayForAppraiserModel {
  ExpiredAllDataSentDeadlines: AppraisalIndexModel[];
  ExpiredVisitScheduleDeadlines: AppraisalIndexModel[];
  Visits: AppraisalIndexModel[];
}

/**
 * @TODO MOVE TO UI LIB
 */
@Component({
  selector: 'mcore-appraiser-agenda',
  templateUrl: './my-schedule.component.html',
})
export class MyScheduleComponent extends UnsuscriptionHandler implements OnInit, AfterViewInit {
  public agendaEvents$: BehaviorSubject<AppraiserUserSummaryEventsByDay[]> = new BehaviorSubject([]);
  public agendaEventsByDay$: BehaviorSubject<AppraisalsEventsByDay> = new BehaviorSubject(null);

  private _today: DateModel;
  private _eventsByDay: AppraisalsEventsByDay = null;

  public minDate: any;
  public maxDate: any;

  @ViewChild(MobileCalendarComponent) calendar: MobileCalendarComponent;

  constructor(private _modalController: ModalController, private _usersService: UsersService, private _authService: AuthService) {
    super();
  }

  ngOnInit() {
    // this._getEvents();

    // this.appraiserDataService.initEventsByDay();
    this.agendaEventsByDay$.pipe(takeUntil(this._componentDestroyed)).subscribe((events) => {
      if (events) {
        this._eventsByDay = events;
        this.agendaDetail();
      }
    });
  }

  ngAfterViewInit() {
    this._getEvents();
  }

  private _getEvents() {
    const appraiserId = this._authService.getUserId();
    const agendaConfig: any /* AppraiserUserEventFilterModel */ = {
      appraiserUserId: appraiserId,
      minDateTime: this.calendar.firstDate,
      maxDateTime: this.calendar.lastDate,
    };

    this.appraiserEvents(agendaConfig);

    this.agendaEvents$.pipe(takeUntil(this._componentDestroyed)).subscribe((events) => {
      if (events && events.length) {
        this.calendar.addEvents(events);
      }
    });
  }

  private async agendaDetail() {
    const modalData: AgendaModalDataModel = {
      Today: this._today,
      VisitScheduled: this._eventsByDay.VisitSchedulded,
      Deadlines: this._eventsByDay.Deadlines,
    };

    const modal = await this._modalController.create({
      component: AppraiserAgendaDetailComponent,
      componentProps: modalData,
    });

    modal.present();
  }

  public async goToDetails(item) {
    this._today = {
      Day: item.date.getDate(),
      Month: item.date.getMonth() + 1,
      MonthName: this.calendar.getName(item.date.getMonth(), this.calendar.getMonthsArray()),
      Year: item.date.getFullYear(),
    };

    this.appraiserEventsByDay(this._today);
  }

  changeCalendarMonth(month: number) {
    this.calendar.initCalendar(month);
    this._getEvents();
  }

  // Service part

  appraiserEvents(agendaConfig: AppraiserUserEventFilterModel) {
    this._usersService
      .ListEventsByDateRange(this._authService.getUserId(), agendaConfig)
      .pipe(take(1))
      .subscribe((events) => {
        this.agendaEvents$.next(events);
      });
  }

  appraiserEventsByDay(date: DateModel) {
    this._usersService
      .ListEventsByDay(date)
      .pipe(take(1))
      .subscribe((events) => {
        const eventsByDay: AppraisalsEventsByDay = {
          VisitSchedulded: events.Visits.map((item) => item),
          Deadlines: events.ExpiredVisitScheduleDeadlines.concat(events.ExpiredAllDataSentDeadlines),
        };

        this.agendaEventsByDay$.next(eventsByDay);
      });
  }

  // Service part
}
