<ion-badge
  *ngIf="chip"
  mode="ios"
  [ngClass]="{
    'chip-button': chip?.clickable,
    primary: chip?.type === ChipType.PRIMARY,
    'primary-shade': chip?.type === ChipType.PRIMARY_SHADE,
    secondary: chip?.type === ChipType.SECONDARY,
    'secondary-tint': chip?.type === ChipType.SECONDARY_TINT,
    tertiary: chip?.type === ChipType.TERTIARY,
    'tertiary-shade': chip?.type === ChipType.TERTIARY_SHADE,
    'tertiary-tint': chip?.type === ChipType.TERTIARY_TINT,
    dark: chip?.type === ChipType.DARK,
    default: chip?.type === ChipType.DEFAULT,
    'default-reverse': chip?.type === ChipType.DEFAULT_REVERSE,
    danger: chip?.type === ChipType.DANGER,
    warn: chip?.type === ChipType.WARNING,
    info: chip?.type === ChipType.INFO,
    success: chip?.type === ChipType.SUCCESS,
    transparent: chip?.type === ChipType.INVERSE,
    'primary-outline': chip?.type === ChipType.PRIMARY_OUTLINE,
    'danger-outline': chip?.type === ChipType.DANGER_OUTLINE,
    'rejected-outline': chip?.type === ChipType.REJECTED_OUTLINE,
    'warning-outline': chip?.type === ChipType.WARNING_OUTLINE,
    active: chip?.active
  }"
>
  <ion-icon *ngIf="chip?.icon" [name]="chip?.icon"></ion-icon>
  <ion-img *ngIf="chip?.image" class="logo" [src]="chip?.image"></ion-img>
  <span>{{ chip?.label | translate | capitalize }}</span>
</ion-badge>
