import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { I18nModule, PipesModule } from '@foxeet/utils-modules';
import { IonicModule } from '@ionic/angular';
import { FormValidationMessagesModule } from '../../form-validation-messages/mobile/form-validation-messages.module';
import { FormRangeComponent } from './form-range.component';

@NgModule({
  declarations: [FormRangeComponent],
  imports: [CommonModule, IonicModule, ReactiveFormsModule, FormValidationMessagesModule, I18nModule.forChild(), PipesModule],
  exports: [FormRangeComponent],
})
export class FormRangeModule {}
