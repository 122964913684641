<ion-item
  [ngClass]="{
    error: control?.errors,
    'non-opacity': readonly,
    suggested: suggested && (control?.value === null || control?.value === undefined || control?.value === '')
  }"
  *ngIf="control && loaded"
>
  <!-- <ion-label [position]="labelPosition" *ngIf="label">{{ label | translate | capitalize }}</ion-label> -->
  <ion-textarea
    label="{{ label ? (label | translate | capitalize) : '' }}"
    labelPlacement="floating"
    [id]="componentId"
    [class]="cssClass"
    [formControl]="control"
    [placeholder]="placeholder ?? undefined"
    (ionBlur)="canEmitOnBlur ? control.updateValueAndValidity() : $event.stopPropagation()"
    [autoGrow]="autoGrow"
    rows="1"
  ></ion-textarea>
</ion-item>

<ui-mobile-form-validation-messages *ngIf="control?.errors" [control]="control"></ui-mobile-form-validation-messages>
