import { EventEmitter, Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { takeUntil } from 'rxjs/operators';
import { UnsuscriptionHandler } from '../components';

export type LoadingServiceEvent = {
  error?: Error | any;
  message: string;
};

@Injectable({ providedIn: 'root' })
export class LoadingService extends UnsuscriptionHandler {
  private _isPresent = false;
  private _presentLoading = false;
  private _dismissLoading = false;
  private _dismissTry = 0;
  private _creationTrys = 0;
  private _spinnerCounter = 0;
  private _spinnerElement!: HTMLIonLoadingElement;

  private readonly createSpinner = new EventEmitter<LoadingServiceEvent>(true);
  private readonly dismissSpinner = new EventEmitter<LoadingServiceEvent>(true);

  constructor(public loadingController: LoadingController) {
    super();
  }

  get onCreateSpinner() {
    return this.createSpinner.pipe(takeUntil(this._componentDestroyed));
  }

  get onDismissSpinner() {
    return this.dismissSpinner.pipe(takeUntil(this._componentDestroyed));
  }

  public present(): void {
    this._spinnerCounter++;
    this.loadSpinner();
  }

  public loadSpinner(): void {
    if (!this._presentLoading && !this._isPresent) {
      this._creationTrys++;
      this._presentLoading = true;

      this.loadingController.create().then((spinnerElement) => {
        this._spinnerElement = spinnerElement;
        this._spinnerElement
          .present()
          .then(() => {
            this.createSpinner.emit({ message: 'Spinner loaded successfully' });
            this._isPresent = true;
            this._presentLoading = false;
            this.clearSpinner();
          })
          .catch((error) => {
            this.createSpinner.emit({
              message: `Spinner creation error, trying another time. Try: ${this._creationTrys}`,
              error,
            });
            if (this._dismissTry < 3) {
              this.loadSpinner();
            } else {
              this._isPresent = false;
              this._presentLoading = false;
            }
          });
      });
    }
  }

  public dismiss(): void {
    this._spinnerCounter--;
    this.clearSpinner();
  }

  public clearSpinner(): void {
    if (!this._dismissLoading && this._isPresent && this._spinnerCounter === 0) {
      this._dismissLoading = true;
      this.loadingController
        .dismiss(this._spinnerElement.id)
        .then((dismissed) => {
          this.dismissSpinner.emit({ message: `Spinner dismissed succesfully :${dismissed}` });
          this._isPresent = false;
          this._dismissLoading = false;
        })
        .catch((error) => {
          this.dismissSpinner.emit({
            message: `Spinner dismiss error, trying another time. Try: ${this._dismissTry}`,
            error,
          });
          if (this._dismissTry < 3) {
            this.clearSpinner();
          } else {
            this._dismissLoading = false;
          }
        });
    }
  }
}
