import { Component, Input } from '@angular/core';
import { ChipModel, ChipType } from '../chip.types';

@Component({
  selector: 'ui-mobile-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
})
export class ChipComponent {
  public ChipType = ChipType;

  @Input() chip: ChipModel | null = null;
}
