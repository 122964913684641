<ion-grid>
  <ion-row class="ion-justify-content-center logo" [ngClass]="{ isIOS: isIOS }">
    <ion-col size="8">
      <ion-img [src]="logo"></ion-img>
    </ion-col>
  </ion-row>

  <ion-row class="ion-justify-content-end version" *ngIf="appVersion">
    <ion-col size="12">
      <p class="small">v{{ appVersion }}</p>
    </ion-col>
  </ion-row>
</ion-grid>
