import { ChipModel } from '@foxeet/ui';

export enum MenuItemType {
  default = 0,
  message = 1,
}

export interface MenuItem {
  id: string;
  type: MenuItemType;
  title?: string;
  url?: string;
  icon?: string;
  img?: string;
  iconRight?: string;
  isDropdown?: boolean;
  children?: MenuItem[];
  chip?: ChipModel;
  opened?: boolean;
  callback?: () => void;
  customColors?: {
    color: string;
    backgroundColor: string;
  };
  authorityRoute?: string;
}

export interface MenuItemsList {
  name: string;
  itemsList: MenuItem[];
}
