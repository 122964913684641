import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
//import { NewGenericService } from "@foxeet/utils/services/new-generic.service";
import { MobileFilter, MobileNDatasource } from '@foxeet/utils/classes';
import { WorkflowService } from '@foxeet/data-access';
import { UnsuscriptionHandler } from '@foxeet/utils/components';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mcore-generic-list-paged',
  templateUrl: 'generic-list-paged.component.html',
  styleUrls: ['generic-list-paged.component.scss'],
})
export class GenericListPagedComponent extends UnsuscriptionHandler implements OnChanges {
  private _dataSourceSubscription: Subscription;
  @Input() threshold = '150px';
  @Input() infiniteScrollDisabled = false;
  @Input() noContentMessage = this._translateService.instant('noContent');

  @Input() filter: MobileFilter;
  @Input() dataSource: MobileNDatasource<any, WorkflowService, MobileFilter>;

  constructor(private _translateService: TranslateService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataSource) {
      if (this._dataSourceSubscription) this._dataSourceSubscription.unsubscribe();
      this._dataSourceSubscription = this.dataSource.entitySubject.subscribe(() => {
        const { page } = this.filter.getCurrentFilter();
        this.dataSource.totalPages <= page ? this.disableInfiniteScroll() : this.enableInfiniteScroll();
      });
    }
  }

  public reloadData(event: CustomEvent = null): void {
    const defaultFilter = this.filter.getDefaultFilter();
    this.filter.setCurrentFilter(defaultFilter);
    this.dataSource.refreshData(event);
  }

  public loadData(event: CustomEvent): void {
    const currentFilter = this.filter.getCurrentFilter();
    this.filter.setCurrentFilter({ ...currentFilter, page: currentFilter.page + 1 });
    this.dataSource.loadData(event);
  }

  public enableInfiniteScroll(): void {
    this.infiniteScrollDisabled = false;
  }

  public disableInfiniteScroll(): void {
    this.infiniteScrollDisabled = true;
  }
}
