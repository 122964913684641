<ion-grid>
  <form class="form" [formGroup]="form">
    <ion-row>
      <ion-col size="12">
        <ui-mobile-form-input-text [label]="'LOGIN_email' | translate" [form]="form" [controlName]="formNames.user"></ui-mobile-form-input-text>
      </ion-col>
    </ion-row>
  </form>

  <ion-row class="ion-justify-content-center">
    <ion-col size-xs="8" size-sm="6" size-md="5" size-lg="5">
      <ion-button (click)="submitRecoverPassword()">{{ 'LOGIN_recover' | translate | uppercase }}</ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
