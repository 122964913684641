import {
  AppraisalAppraiserSentStates,
  AppraisalMigrationOrigins,
  AppraisalOrderTypes,
  AppraisalPurposes,
  AppraisalVinculations,
  AppraisalVisitTypes,
  AssetStates,
  AssetSubTypologies,
  AssetTypologies,
  AssetUses,
  AssetWorkStates,
  BuildingStates,
  ContractIds,
  EntityTypes,
  FeeOptions,
  FloorTypes,
  InvoiceFlowDays,
  LicenseeTypes,
  OccupationStates,
  PublicProtections,
  RedirectionComponents,
  ReportTypes,
  ResidenceTypes,
  SpanishNationalBankPurposes,
  StreetTypes,
  SuitableTypes,
  ValorationMethods,
  VinculationTypes,
  WorkTypes,
  WorkerRoles,
  WorkflowItemTypes,
} from '../enums';
import { ModalModel } from './modal.model';
import { VisitProposalDataModel } from './order-visit.model';
import { ApiPropertyRegister } from './register.models';
import { UserIndexModel } from './user.model';
import { IWorkflowStepModel } from './workflow-step.model';

export interface AppraisalFilterModel {
  id: number;
  ids: number[];
  currentWorkflowStep: number;
  currentWorkflowSteps: number[];
  excludedCurrentWorkflowSteps: number[];
  batchId: number;
  isFinished: boolean;
  finishedMaxDateTime: string;
  finishedMinDateTime: string;
  addedMaxDateTime: string;
  addedMinDateTime: string;
  isCancelled: boolean;
  cancelledMaxDateTime: string;
  cancelledMinDateTime: string;
  withIncidents: boolean;
  withIncidentsNotResolved: boolean;
  withIncidentsMaxDateTime: string;
  withIncidentsMinDateTime: string;
  withClaims: boolean;
  withClaimsNotResolved: boolean;
  withClaimsMaxDateTime: string;
  withClaimsMinDateTime: string;
  excludedId: number;
  excludedIds: number[];
  summary: string;
  activatedFiltersCount: number;
  orderFieldName: string;
  orderFieldAsc: boolean;
  possibleOrderFieldNames: string[];
  withRevisions: boolean;
  withRevisionsNotResolved: boolean;
  withRevisionsMaxDateTime: string;
  withRevisionsMinDateTime: string;
  rootId: number;
  rootIds: number[];
  parentId: number;
  parentIds: number[];
  licenseeId: number;
  licenseeIds: number[];
  licenseeIdOrs: number[];
  workerRoles: number[];
  appraiserAllDataSentDeadlineMaxDateTime: string;
  appraiserAllDataSentDeadlineMinDateTime: string;
  appraiserAllDataSentMaxDateTime: string;
  appraiserAllDataSentMinDateTime: string;
  cadastralReferences: string[];
  exchangeFinishDateTime: string;
  exchangeFinishMaxDateTime: string;
  exchangeFinishMinDateTime: string;
  excludePortfolioId: number;
  excludePortfolioIds: number[];
  executionToolId: string;
  executionToolIds: string[];
  externalRecordId: string;
  externalRecordIds: string[];
  isAppraiserAllDataSentDeadlineExpired: boolean;
  isCustomerDeadlineExpired: boolean;
  isInConfirmedInvoice: boolean;
  isInExchange: boolean;
  isExecutedByIvForce: boolean;
  isVisitScheduleDeadlineExpired: boolean;
  notInvoice: boolean;
  orderRequestId: number;
  orderType: AppraisalOrderTypes;
  orderTypes: AppraisalOrderTypes[];
  portfolioId: number;
  portfolioIds: number[];
  purpose: AppraisalPurposes;
  purposes: AppraisalPurposes[];
  recordId: string;
  recordIdContains: string;
  recordIds: string[];
  recordIdsContains: string[];
  recordIdOrExecutionToolIdOrExternalRecordId: string;
  recordIdOrExecutionToolIdOrExternalRecordIds: string[];
  exchangeFinishMinutesRemaining: number;
  exchangeMinFee: number;
  validationFinishMaxDatetime: string;
  validationFinishMinDatetime: string;
  validationStartMaxDatetime: string;
  validationStartMinDatetime: string;
  visitFinishMaxDateTime: string;
  visitFinishMinDateTime: string;
  visitScheduleDeadlineMaxDateTime: string;
  visitScheduleDeadlineMinDateTime: string;
  visitScheduleMaxDateTime: string;
  visitScheduleMinDateTime: string;
  visitStartMaxDateTime: string;
  visitStartMinDateTime: string;
  visitType: AppraisalVisitTypes;
  visitTypes: AppraisalVisitTypes[];
  distanceBetweenPointMaxMeters: number;
  distanceBetweenPointMinMeters: number;
  oportunityId: string;
  containsReportWithAssetCadastralReferenceContains: string;
  containsReportWithAssetIdufirContains: string;
  containsReportWithAssetRegisteredPropertyNumberContains: string;
  containsReportWithAssetPropertyRegisterGeoSmartaId: string;
  containsReportWithAssetState: AssetStates;
  containsReportWithAssetUse: AssetUses;
  containsReportWithAssetTypology: AssetTypologies;
  containsReportWithAssetSubTypology: AssetSubTypologies;
  workflowItemLicenseeTypeAndLicenseeIds: Map<LicenseeTypes, number[]>;
  workerRoleAndUserIds: Map<WorkerRoles, number[]>;
  workflowItemType: number;
}

export interface AppraissalDatetimesModel {
  CustomerDeadlineDateTime: Date;
  VisitScheduleDeadlineDateTime: Date;
  AppraiserAllDataSentDeadlineDateTime: Date;
}

export interface AppraissalGetDeadlinesDatetimesModel {
  AppraisalPurpose: AppraisalPurposes;
  AssetTypologies: AssetTypologies;
}

export interface AppraisalEditInvoiceDataModel {
  id: number;
  invoiceMonth: number;
  invoiceYear: number;
  feeOfTechnical: number;
  commercialTechnicalFee: number;
  isDailyInvoiced: boolean;
  invoiceFlowDay: InvoiceFlowDays;
}

export interface AppraisalInvoiceDataModel {
  id: number;
  invoiceId: number;
  invoiceMonth: number;
  invoiceYear: number;
}

export interface AppraisalsMoveToFinished {
  AppraisalIds: number;
}

export interface AppraisalStepData {
  currentStep: string;
  numberOfAppraisals: number;
  averageTime: string;
}

export enum DefaultAppraisalFiltersEnum {
  All = 0,
  PendingToAssignManager,
  ToAssign,
  PendingToSchedule,
  ScheduledByTec,
  InProcess,
  DeliveredByAppraiser,
  // PendingValidation,
  ValidationProcess,
  ValiDated,
  DeliveredToClient,
  Alive,
  Cancelled,
}

export enum DefaultAppraisalFiltersNameEnum {
  'all' = 0,
  'pendingToAssignManager',
  'toAssign',
  'tendingToSchedule',
  'scheduledByTec',
  'inProcess',
  'deliveredByAppraiser',
  // 'pendingValidation',
  'validationProcess',
  'validated',
  'deliveredToClient',
  'alive',
  'cancelled',
}

export interface AppraisalWallCommentIndexModel {
  WorkflowItemWallCommentId: number;
  WorkflowItemId: number;
  WorkflowItemWallCommentParentId: number;
  Comment: string;
  AddedUserId: number;
  AddedUser: UserIndexModel;
  AddedDateTime: Date;
  LastUpDatedUserId: number;
  LastUpDatedDateTime: Date;
  Replies: AppraisalWallCommentIndexModel[];
}

export interface IAppraisalAssetFormData {
  block?: string;
  cadastralReference?: string;
  constructionYear?: number;
  countryThreeLetterISORegionName: string;
  floorType?: FloorTypes;
  gate?: string;
  ineCodProv?: number;
  ineCodMun?: number;
  locality: string;
  postalCode: string;
  propertyRegisterGeoSmartaId?: any;
  province: string;
  registryInfoBook?: any;
  registryInfoIdufir?: any;
  registryInfoInscription?: any;
  registryInfoRealEstateNumber?: any;
  registryInfoSection?: any;
  registryInfoVolume?: any;
  stairs?: string;
  streetName: string;
  streetNumber: string;
  surface?: number;
  typology: any;
  use: any;
  dgcCodVia: any;
  ineCodVia: any;
  parentId?: any;
}

export interface WorkflowItemAssetCreateByAssetIdsModel {
  WorkFlowItemReportId: number;
  AssetIds: number[];
}

/////////////////////////////////////////////////////////////////
// Nuevos modelos

export interface AppraisalEditModel {
  id: number;
  parentId: number;
  workflowItemLicensees: WorkflowItemLicenseeIndexModel[];
  appraiserInvoice_CommercialTechnicalFee: number;
  appraiserInvoice_Fee: number;
  block: string;
  canBeLaunchedInExchange: boolean;
  country: string;
  countryThreeLetterISORegionName: string;
  countryConfigurationId: number;
  dgcCodVia: number;
  exchangeFeeProposal: number;
  executionToolId: string;
  externalRecordId: string;
  floorType: FloorTypes;
  gate: string;
  ineCodMun: number;
  ineCodProv: number;
  ineCodVia: string;
  isExecutedByIvForce: boolean;
  isInExchange: boolean;
  latitude: number;
  locality: string;
  longitude: number;
  oportunityId: string;
  orderRequestId: number;
  orderType: AppraisalOrderTypes;
  otherExpenses_AppraiserInvoice: number;
  otherExpensesRappel_AppraiserInvoice: number;
  otherExpensesSimpleNote_AppraiserInvoice: number;
  portal: string;
  postalCode: string;
  prescriptorComments: string;
  prescriptorOffice: string;
  province: string;
  provision_SalePrice: number;
  provision_Fee: number;
  provision_CommercialTechnicalFee: number;
  provision_OtherExpenses: number;
  provision_OtherExpensesRappel: number;
  provision_OtherExpensesSimpleNote: number;
  provision_FeesAndPublicDocuments: number;
  purpose: AppraisalPurposes;
  recordId: string;
  requestElements: AppraisalRequestElementModel[];
  salePrice_AppraiserInvoice: number;
  stairs: string;
  streetName: string;
  streetNumber: string;
  streetType: StreetTypes;
  spanishNationalBankPurpose: SpanishNationalBankPurposes;
  vinculation: AppraisalVinculations;
  vinculationType: VinculationTypes;
  externalVinculationId: string;
  visitEmail: string;
  visitName: string;
  visitObservations: string;
  visitPhone: string;
  visitProposals: VisitProposalDataModel[];
  visitType: AppraisalVisitTypes;
  withEco8052003: boolean;
  reportType: ReportTypes;
  cultureCodeCurrency: string;
  cultureCodeLanguage: string;
  workType: WorkTypes;
}

export interface AppraisalModel {
  canAddBdeData: boolean;
  isReportedToBde: boolean;
  id: number;
  parentId: number;
  rootId: number;
  currentStepValue: number;
  addedDateTime: string;
  addedUserId: number;
  isFinished: boolean;
  isCancelled: boolean;
  cancelledUserId: number;
  workflowItemLicensees: WorkflowItemLicenseeIndexModel[];
  workers: WorkflowItemWorkerIndexModel[];
  addressGeneratedName: string;
  appraiserAllDataSentDatetime: string;
  appraiserAllDataSentDeadlineDateTime: string;
  block: string;
  cancelledDateTime: string;
  country: string;
  customerDeadlineDateTime: string;
  exchangeFinishDateTime: string;
  executionToolId: string;
  externalRecordId: string;
  finishedDateTime: string;
  floorType: FloorTypes;
  fullAddressGeneratedName: string;
  gate: string;
  hasIncidentsNotResolved: boolean;
  isAssetChanged: boolean;
  isExecutedByIvForce: boolean;
  isInExchange: boolean;
  isInvoicedInOrderRequest: boolean;
  locality: string;
  orderType: AppraisalOrderTypes;
  portal: string;
  postalCode: string;
  profileMapMobileImageFileWebPath: string;
  province: string;
  recordId: string;
  stairs: string;
  state: AssetStates;
  streetName: string;
  streetNumber: string;
  streetType: StreetTypes;
  subTypology: AssetSubTypologies;
  typology: AssetTypologies;
  use: AssetUses;
  validationFinishDatetime: string;
  validationStartDatetime: string;
  valorationValue: number;
  visitFinishDateTime: string;
  visitScheduleDateTime: string;
  visitScheduleDeadlineDateTime: string;
  visitStartDateTime: string;
  visitType: AppraisalVisitTypes;
  withClaimsNotResolved: boolean;
  confirmed_OtherExpenses: number;
  confirmed_OtherExpensesRappel: number;
  confirmed_OtherExpensesSimpleNote: number;
  confirmed_SalePrice: number;
  confirmed_CommercialTechnicalFee: number;
  confirmed_FeesAndPublicDocuments: number;
  expectedBillingDate: Date;
  confirmed_Fee: number;
  withRevisionsNotResolved: boolean;
  appraiserInvoice_CommercialTechnicalFee: number;
  appraiserInvoice_Fee: number;
  vinculation: AppraisalVinculations;
  vinculationType: VinculationTypes;
  externalVinculationId: string;
  valorationTotalValue: number;
  appraisalReadedByAppraiserDateTime: string;
  appraiserInvoice_InvoiceMonth: number;
  appraiserInvoice_InvoiceYear: number;
  appraiserInvoice_IsDailyInvoiced: boolean;
  appraiserInvoice_InvoiceFlowDay: InvoiceFlowDays;
  appraiserPushLastSentDatetime: string;
  appraiserPushReadDatetime: string;
  appraiserPushRecivedDatetime: string;
  appraiserSentState: AppraisalAppraiserSentStates;
  activeReportId: number;
  appraiserUserReasonSelection: string;
  averageClaimsCount: number;
  averageElapsedTimeToSolveClaim: number;
  averageElapsedTimeToSolveIncident: number;
  averageIncidentsCount: number;
  batchId: number;
  canAssignAppraiserUser: boolean;
  canAssignManagerProcessUser: boolean;
  canAssignManagerTechnicalUser: boolean;
  canBeLaunchedInExchange: boolean;
  canCancel: boolean;
  cancelledDescription: string;
  cancelledUserFullName: string;
  canChangeFeeOfTechnicalOnEditPanel: boolean;
  canCreateIncident: boolean;
  canCreateIncidentByAppraiser: boolean;
  canCreateReclamation: boolean;
  canEdit: boolean;
  canEditInvoiceData: boolean;
  canEditInvoiceDataInCancellation: boolean;
  canFinish: boolean;
  canMarkAsReadByAppraiser: boolean;
  canPrint: boolean;
  canSendDataFromVisitByAppraiser: boolean;
  canSetAsDataSendFinishedByAppraiser: boolean;
  canSetFinishVisitByAppraiser: boolean;
  canSetStartVisitByAppraiser: boolean;
  canSetIsDailyInvoiced: boolean;
  canSetVisitScheduleDateTimeByAppraiser: boolean;
  canShowClaimsTab: boolean;
  canShowRevisionsTab: boolean;
  canStartValidation: boolean;
  canValidate: boolean;
  censusSectionName: string;
  claimsCount: number;
  claimsNotResolvedCount: number;
  countryThreeLetterISORegionName: string;
  countryConfigurationId: number;
  dgcCodVia: number;
  elapsedTimeCount: number;
  elapsedTimeFromAllDataSentUntilValidationFinished: number;
  elapsedTimeFromAssignedProcessManagerUntilAppraiserDataSent: number;
  elapsedTimeFromDataSentUntilTechnicalManagerAssigned: number;
  elapsedTimeFromSuccessfulValidationUntilCustomerSent: number;
  elapsedTimeFromTechnicalManagerAssignedUntilFinishValidation: number;
  elapsedTimeOfValidation: number;
  elapsedTimeToAssignAppraiser: number;
  elapsedTimeToAssingProcessManager: number;
  elapsedTimeToDateVisit: number;
  elapsedTimeToFinishAppraisal: number;
  elapsedTimeToFinishAppraisalRemovingValidation: number;
  elapsedTimeToFinishVisit: number;
  elapsedTimeToFinishWorkAppraiser: number;
  elapsedTimeToSendData: number;
  exchangeFeeProposal: number;
  externalNotificationError: string;
  feeOption: FeeOptions;
  hasClaims: boolean;
  hasClaimsNotResolved: boolean;
  hasIncidents: boolean;
  hasRevisions: boolean;
  hasRevisionsNotResolved: boolean;
  incidentsCount: number;
  incidentsNotResolvedCount: number;
  ineCodMun: number;
  ineCodProv: number;
  ineCodVia: string;
  isAssetAppraisal: boolean;
  isExternalReceivedNotificationSuccessfuly: boolean;
  isInExchangeAndExpired: boolean;
  isLocated: boolean;
  isQualitySurveySent: boolean;
  latitude: number;
  longitude: number;
  oportunityId: string;
  orderRequestId: number;
  portfolioId: number;
  portfolioName: string;
  prescriptorComments: string;
  prescriptorOffice: string;
  provision_SalePrice: number;
  provision_Fee: number;
  provision_CommercialTechnicalFee: number;
  provision_OtherExpenses: number;
  provision_OtherExpensesRappel: number;
  provision_OtherExpensesSimpleNote: number;
  purpose: AppraisalPurposes;
  requestElements: AppraisalRequestElementModel[];
  revisionsCount: number;
  revisionsNotResolvedCount: number;
  steps: IWorkflowStepModel[];
  spanishNationalBankPurpose: SpanishNationalBankPurposes;
  validationLastRevisionRequiredDatetime: string;
  visitEmail: string;
  visitName: string;
  visitObservations: string;
  visitPhone: string;
  visitProposals: VisitProposalDataModel[];
  withEco8052003: boolean;
  canShowVinculatedTab: boolean;
  reportType: ReportTypes;
  canSkipProfesionalSteps: boolean;
  externalConnectionContractId: ContractIds;
  cultureCodeCurrency: string;
  cultureCodeLanguage: string;

  // TODO: ONLY FOR TASAFY
  academyCourseId: number;
  /**
   * Roles for appraisal. All basic or all expert
   */
  licensePlanRoles: string[];

  // validations
  reportErrorCount: number;
  reportWarningCount: number;
  reportInfoCount: number;
  hasExternalContract: boolean;
  hasExternalContractWithIncidentNotification: boolean;
  hasAntecedents: boolean;
  recalculateAntecedents: boolean;

  externalIntermediateWorkEvaluationLastAppraisalDatetime: string;
  externalIntermediateWorkEvaluationLastAppraisalWorkProgressPercent: number;
  externalIntermediateWorkEvaluationLastAppraisalTotalValue: number;

  appraisalVinculationSummaries: AppraisalVinculationSummaries[];
  isVinculatedByDataPickAndAllowToImportData: boolean;
  isMigrationValoration: boolean;
  migrationOrigin: AppraisalMigrationOrigins;
  isAutomaticInvoice: boolean;
  workType: WorkTypes;
}

export interface AppraisalVinculationSummaries {
  appraisalVinculation: AppraisalVinculations;
  currentStepValue: number;
  id: number;
  recordId: string;
}

export interface AppraisalManagersModel {
  id: number;
  processManagerUser: UserInAppraisalModel;
  technicalManagerUser: UserInAppraisalModel;
  appraiserUser: UserInAppraisalModel;
}

export interface UserInAppraisalModel {
  id: number;
  firstName: string;
  lastName: string;
  fileWebPath: string;
}

export interface AppraisalRequestElementModel {
  id: number;
  workflowItemId: number;
  occupationState: OccupationStates;
  buildingState: BuildingStates;
  publicProtection: PublicProtections;
  surface: number;
  constructionYear: number;
  cadastralReference: string;
  cadastralReferenceContainerBuilding: string;
  countryThreeLetterISORegionName: string;
  ineCodProv: number;
  ineCodProvStr: string;
  ineCodMun: number;
  ineCodMunStr: string;
  ineCodLocality: number;
  ineCodLocalityStr: string;
  dgcCodVia: number;
  ineCodVia: string;
  censusSectionName: string;
  streetType: StreetTypes;
  streetName: string;
  streetNotExists: boolean;
  streetNumber: string;
  block: string;
  stairs: string;
  portal: string;
  floorType: FloorTypes;
  gate: string;
  locality: string;
  province: string;
  postalCode: string;
  country: string;
  fullAddressGeneratedName: string;
  addressGeneratedName: string;
  state: AssetStates;
  use: AssetUses;
  typology: AssetTypologies;
  subTypology: AssetSubTypologies;
  latitude: number;
  longitude: number;
  idufir: string;
  registeredPropertyNumber: string;
  propertyRegisterGeoSmartaId: string;
  propertyRegisterName: string;
  propertyRegister: ApiPropertyRegister;
  volume: string;
  book: string;
  paper: string;
  section: string;
  inscription: string;
  orderRequestElementId: number;
}

export interface AppraisalCancellationEditModel {
  id: number;
  description: string;
  changeInvoiceData: boolean;
  invoiceMonth: number;
  invoiceYear: number;
  feeOfTechnical: number;
  isDailyInvoiced: boolean;
  deliverToClient: boolean;
}

export interface AppraisalSuccededValidationModel {
  valorationTotalValue: number;
  suitableType: SuitableTypes;
  observations: string;
}

export interface EconomicValidationModel {
  id: number;
  confirmed_Fee: number;
  confirmed_CommercialTechnicalFee: number;
  confirmed_OtherExpenses: number;
  confirmed_OtherExpensesSimpleNote: number;
  confirmed_OtherExpensesRappel: number;
  confirmed_FeesAndPublicDocuments: number;
  isDailyInvoiced: boolean;
}

export interface AppraisalVisitScheduleDateTimeModel {
  appraisalId: number;
  visitScheduleDateTime: string;
}

export interface AppraisalIndexModel {
  id: number;
  parentId: number;
  rootId: number;
  currentStepValue: number;
  licenseeId: number;
  addedDateTime: string;
  addedUserId: number;
  isFinished: boolean;
  isCancelled: boolean;
  cancelledUserId: number;
  workflowItemLicensees: WorkflowItemLicenseeIndexModel[];
  workers: WorkflowItemWorkerIndexModel[];
  addressGeneratedName: string;
  appraiserAllDataSentDatetime: string;
  appraiserAllDataSentDeadlineDateTime: string;
  block: string;
  cancelledDateTime: string;
  country: string;
  customerDeadlineDateTime: string;
  exchangeFinishDateTime: string;
  executionToolId: string;
  externalRecordId: string;
  finishedDateTime: string;
  floorType: FloorTypes;
  fullAddressGeneratedName: string;
  gate: string;
  hasIncidentsNotResolved: boolean;
  isAssetChanged: boolean;
  isExecutedByIvForce: boolean;
  isInExchange: boolean;
  isInvoicedInOrderRequest: boolean;
  locality: string;
  orderType: AppraisalOrderTypes;
  portal: string;
  postalCode: string;
  profileMapMobileImageFileWebPath: string;
  province: string;
  recordId: string;
  stairs: string;
  state: AssetStates;
  streetName: string;
  streetNumber: string;
  streetType: StreetTypes;
  subTypology: AssetSubTypologies;
  typology: AssetTypologies;
  use: AssetUses;
  validationFinishDatetime: string;
  validationStartDatetime: string;
  valorationValue: number;
  visitFinishDateTime: string;
  visitScheduleDateTime: string;
  visitScheduleDeadlineDateTime: string;
  visitStartDateTime: string;
  visitType: AppraisalVisitTypes;
  withClaimsNotResolved: boolean;
  confirmed_OtherExpenses: number;
  confirmed_OtherExpensesRappel: number;
  confirmed_OtherExpensesSimpleNote: number;
  confirmed_SalePrice: number;
  confirmed_CommercialTechnicalFee: number;
  confirmed_Fee: number;
  confirmed_FeesAndPublicDocuments: number;
  withRevisionsNotResolved: boolean;
  appraiserInvoice_CommercialTechnicalFee: number;
  appraiserInvoice_Fee: number;
  vinculation: AppraisalVinculations;
  vinculationType: VinculationTypes;
  externalVinculationId: string;
  valorationTotalValue: number;
  deadlineGrade: number;
  executionGrade: number;
  workflowItemType: WorkflowItemTypes;
}

// ENTITIES AND USERS
export interface LicenseeHeaderIndexModel {
  id: number;
  fullName: string;
  fileWebPath: string;
  nationalIdentityDocument: string;
  entityType?: EntityTypes;
}

export interface WorkflowItemLicenseeIndexModel {
  id: number;
  licenseeId: number;
  workflowItemId: number;
  licenseeTypeValue: number;
  licensee: LicenseeHeaderIndexModel;
}

export interface WorkflowItemIndexModel {
  id: number;
  parentId: number;
  rootId: number;
  currentStepValue: number;
  addedDateTime: string;
  addedUserId: number;
  isFinished: boolean;
  isCancelled: boolean;
  cancelledUserId: number;
  workflowItemLicensees: WorkflowItemLicenseeIndexModel[];
  workers: WorkflowItemWorkerIndexModel[];
}

export interface UserHeaderIndexModel {
  id: number;
  fileWebPath: string;
  fullName: string;
}

export interface WorkflowItemWorkerIndexModel {
  id: number;
  userId: number;
  user: UserHeaderIndexModel;
  workflowItemId: number;
  workerRoleValue: WorkerRoles;
}

export interface WorkflowItemWorkerModel {
  id: number;
  userId: number;
  workflowItemId: number;
  workerRoleValue: WorkerRoles;
}

export interface AppraisalAssignWorkerModel {
  workflowItemWorkers: WorkflowItemWorkerModel[];
  reason: string;
}

export interface MyWorkDto {
  profileImagePath: string;
  isAdmin: boolean;
  fullName: string;
  licenseeId: number;
  pendingAppraisals: number;
  finishedAppraisals: number;
  totalAppraisals: number;
  adminPendingAppraisals: number;
  adminFinishedAppraisals: number;
  adminTotalAppraisals: number;
}

export interface AppraisalModalModel extends ModalModel {
  redirectionComponent: RedirectionComponents;
}

export interface AppraisalSkipProfesionalStepsModel {
  appraisalId: number;
  visitScheduleDateTime: any;
  visitStartDateTime: any;
  visitFinishDateTime: any;
}

export interface SetAppraisalBdeAssetsModel {
  validationFinishDatetime: any;
  bdeAssets: AppraisalBdeAssetModel[];
}

export interface AppraisalBdeAssetModel {
  id: number;
  appraisalId: number;
  state: AssetStates;
  use: AssetUses;
  typology: AssetTypologies;
  subTypology: AssetSubTypologies;
  residenceType: ResidenceTypes;
  workState: AssetWorkStates;
  adoptedValue: number;
  hetValue: number;
  adoptedSurface: number;

  valorationMethod: ValorationMethods;
  future_ValorationMethod: ValorationMethods;

  kh: number;
  specificWarning: boolean;

  riskFreePercent: number;
  riskPercentage: number;
  appliedPremiumPercent: number;
  appliedPremiumPercentage: number;
  ipcPercent: number;
  ipcPercentage: number;
  freeRateType: number;
  freeRateConstantPercent: number;
  externalFinancingIncreasePercent: number;
  externalFinancingIncreaseJustification: string;
  updateRatePercent: number;
  updateRatePercentage: number;
  updateMonthlyRatePercent: number;
  updateMonthlyRatePercentage: number;

  developerProfitPercentage: number;
  pendinDeadline: number;
  expectegdMarketEvolution: number;
  projectDuration: number;
  pendingUsefulLife: number;
  residualMethod_MainBuldingElement_Use?: AssetUses;
  residualMethod_MainBuldingElement_Typology?: AssetTypologies;
  residualMethod_MainBuldingElement_ResidenceType?: ResidenceTypes;
}
