<ion-row>
  <ion-col class="no-padding">
    <ion-segment mode="md" *ngIf="navigationConfig" class="wrapper" [value]="selectedPath">
      <ion-segment-button
        id="{{ nav.id }}"
        *ngFor="let nav of navigationConfig.items"
        expand="full"
        [ngClass]="{ selected: selectedPath === nav.url }"
        [value]="nav.url"
        (click)="onChangeSegment(nav.url)"
      >
        <ion-label>{{ nav.title | translate }}</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-col>
</ion-row>
