import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from '@foxeet/utils-modules';
import { IonicModule } from '@ionic/angular';
import { ChipModule } from '../../../general-components/chip/mobile/chip.module';
import { FormValidationMessagesModule } from '../../form-validation-messages/mobile/form-validation-messages.module';
import { FormInputRadioListComponent } from './form-input-radio-list.component';

@NgModule({
  declarations: [FormInputRadioListComponent],
  imports: [CommonModule, ReactiveFormsModule, IonicModule, I18nModule.forChild(), FormValidationMessagesModule, ChipModule],
  exports: [FormInputRadioListComponent],
})
export class FormInputRadioListModule {}
