import { Component, Input, OnInit } from '@angular/core';
import { InternationalizationTypes } from '@foxeet/domain';
import { ActionsContainerButton, ActionsContainerButtonTypes } from '../../domain';

@Component({
  selector: 'mcore-actions-container',
  templateUrl: './actions-container.component.html',
  styleUrls: ['./actions-container.component.scss'],
})
export class ActionsContainerComponent implements OnInit {
  @Input() actions: ActionsContainerButton[] = [];
  @Input() title: string;
  @Input() item: any;
  @Input() index = 0;
  @Input() internationalizationType: InternationalizationTypes = null;
  @Input() componentId: string;

  public isEditable: boolean = true;

  actionButtonTypes = ActionsContainerButtonTypes;

  ngOnInit(): void {
    if (this.item && this.item.hasOwnProperty('isEditable')) {
      this.isEditable = this.item.isEditable;
    }
  }

  execCallback(action: ActionsContainerButton, item: any) {
    action?.extraParams ? action.callback(item, ...action?.extraParams) : action.callback(item);
  }
}
