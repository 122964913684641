import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CultureSelectors } from '@foxeet/chests/culture';
import { CurrencyEnumModel, EnumsModel } from '@foxeet/domain';
import { AuthService } from '@foxeet/feature/auth';
import { DynamicConfigLoaderService } from '@foxeet/utils-modules';
import { UnsuscriptionHandler } from '@foxeet/utils/components';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { InternationalizationModalComponent } from '../internationalization-modal/internationalization-modal.component';

@Component({
  selector: 'mcore-internationalization-button',
  templateUrl: './internationalization-button.component.html',
})
export class InternationalizationButtonComponent extends UnsuscriptionHandler implements OnInit {
  @Input() showLanguages = true;
  @Input() showCurrencies = true;

  @Output() cultureEmitter: EventEmitter<{ cultureCodeCurrency: string; cultureCodeLanguage: string }> = new EventEmitter();

  public languages: EnumsModel[];
  public currencies: CurrencyEnumModel[];

  constructor(private _modalController: ModalController, private _authService: AuthService, private _dCLS: DynamicConfigLoaderService, private readonly store: Store) {
    super();
  }

  ngOnInit(): void {
    if (this.showLanguages) {
      this.store
        .select(CultureSelectors.CultureLanguages)
        .pipe(takeUntil(this._componentDestroyed))
        .subscribe((languages) => (this.languages = languages));
    }

    if (this.showCurrencies) {
      this.store
        .select(CultureSelectors.CultureCurrencies)
        .pipe(takeUntil(this._componentDestroyed))
        .subscribe((currencies) => (this.currencies = currencies));
    }
  }

  async openInternationalizationModal() {
    const modal = await this._modalController.create({
      component: InternationalizationModalComponent,
      componentProps: {
        cultureCodeCurrency: this._authService.getPropertyFromUserData('cultureCodeCurrency'),
        cultureCodeLanguage: this._authService.getPropertyFromUserData('cultureCodeLanguage'),
        languages: this.languages.map((l) => {
          const av = this._dCLS.Options.languagesConfig.availableLanguages.find((a) => a.lang === l.value);
          return {
            ...l,
            cultureCodeLanguage: av.cultureCodeLanguage,
          };
        }),
        currencies: this.currencies,
      },
    });

    modal.onDidDismiss().then(({ data }) => {
      if (data) {
        this.cultureEmitter.emit(data);
      }
    });

    modal.present();
  }
}
