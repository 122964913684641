import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MobileCalendarInfoComponent } from '../calendar-info/calendar-info.component';
import { EventType } from '@foxeet/domain';

/**
 * @TODO MOVE TO UI LIB
 */
@Component({
  selector: 'mcore-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class MobileCalendarComponent implements OnInit {
  @Output() calendarDetail: EventEmitter<number> = new EventEmitter();
  @Output() changeMonth: EventEmitter<number> = new EventEmitter();
  @ViewChild('popover') popover;

  protected _MONTHS = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];

  public _INITIAL_WEEK = ['L', 'M', 'X', 'J', 'V', 'S', 'D'];

  private CALENDAR_GRID_SIZE: number = 35;
  private DAYS_OUT_OFF_MONTH_CLASS: string = 'out-of-month';
  private DAYS_IN_MONTH_CLASS: string = 'in-month';

  public _calendar: any = [];
  public _currentDate: Date;
  public _currentYear: number;
  public _currentMonth: number;
  public _currentDay: number;
  public _currentWeekDay: number;

  public _firstDate: Date;
  public _lastDate: Date;

  public _monthName: string = '';

  public _events: any = [];
  public isOpen = false;
  public dataEvent;

  constructor(private _modalController: ModalController) {}

  ngOnInit() {
    this._currentDate = new Date();
    this._currentYear = this._currentDate.getFullYear();
    this._currentMonth = this._currentDate.getMonth();
    this._currentDay = this._currentDate.getDate();
    this._currentWeekDay = this._currentDate.getDay();

    this.initCalendar(this._currentMonth);
  }

  set events(events) {
    this._events = events;
  }

  get currentMonth() {
    return this._currentMonth;
  }

  get currentYear() {
    return this._currentYear;
  }

  get firstDate() {
    return this._firstDate;
  }

  get lastDate() {
    return this._lastDate;
  }

  initMonthAndYearValues(month: number) {
    this._currentMonth = month;

    if (month > 11) {
      this._currentMonth = 0;
      this._currentYear = this._currentYear + 1;
    }

    if (month < 0) {
      this._currentMonth = 11;
      this._currentYear = this._currentYear - 1;
    }
  }

  addEvents(events: any) {
    this._calendar.forEach((item) => {
      events.map((event) => {
        const eventDate = new Date(event.eventDateTime);
        event.events.map((subEvents) => {
          if (eventDate.getDate() === item.date.getDate() && eventDate.getMonth() === item.date.getMonth() && eventDate.getFullYear() === item.date.getFullYear()) {
            if (subEvents.eventType === EventType.deadLine) item['hasExpiredDeadlines'] = true;
            if (subEvents.eventType === EventType.visit) item['hasVisits'] = true;
            if (subEvents.eventType === EventType.absent) item['absent'] = true;
            if (event.events.length)
              item['eventData'] = {
                events: event.events,
                eventDateTime: eventDate,
              };
          }
        });
      });
    });
  }

  addDayClasses(day: number, extraClass: string) {
    this._calendar.find((item) => {
      if (item.day === day && item.date.getMonth() === new Date().getMonth()) {
        item.class = `${item.class} ${extraClass}`;
      }
    });
  }

  buildDay(classes: string, day: number, date: any) {
    return { class: classes, day: day, date: date };
  }

  pushCalendarDays({ init, end, month, year }, dayClass: string) {
    for (let i = init; i < end; i++) {
      this._calendar.push(this.buildDay(dayClass, i, new Date(year, month, i)));
    }
  }

  initCalendar(month: number) {
    this._calendar = [];

    this.initMonthAndYearValues(month);

    const lastMonthNumber = this._currentMonth;
    const currentMonthNumber = this._currentMonth + 1;

    const lastMonthDays = this.getMonthDays(this._currentYear, lastMonthNumber);
    const currentMonthDays = this.getMonthDays(this._currentYear, currentMonthNumber);

    let firstDayOfMonth = this.getWeekDay(this._currentYear, this._currentMonth, 1);
    //TODO: This is for Spanish Calendar. Implement English calendar in the future.
    firstDayOfMonth = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;

    /* Last Month Configuration */
    const initLastMonth = lastMonthDays - firstDayOfMonth + 1;
    const configLastMonth = {
      init: initLastMonth,
      end: lastMonthDays + 1,
      month: this._currentMonth - 1,
      year: this._currentYear,
    };

    this.pushCalendarDays(configLastMonth, this.DAYS_OUT_OFF_MONTH_CLASS);
    this._firstDate = new Date(this._currentYear, this._currentMonth - 1, initLastMonth);

    /* Current Month Configuration */
    const configCurrentMonth = {
      init: 1,
      end: currentMonthDays + 1,
      month: this._currentMonth,
      year: this._currentYear,
    };
    this.pushCalendarDays(configCurrentMonth, this.DAYS_IN_MONTH_CLASS);

    /* Next Month Configuration */
    const configNextMonth = {
      init: 1,
      end: this.CALENDAR_GRID_SIZE - this._calendar.length + 1,
      month: this._currentMonth + 1,
      year: this._currentYear,
    };
    this.pushCalendarDays(configNextMonth, this.DAYS_OUT_OFF_MONTH_CLASS);
    this._lastDate = new Date(this._currentYear, this._currentMonth + 1, 1);

    this._monthName = this.getName(this._currentMonth, this._MONTHS);

    this.addDayClasses(this._currentDay, 'today');

    // this.addEvents
  }

  getMonthsArray() {
    return this._MONTHS;
  }

  getMonthDays(year: number, month: number) {
    return new Date(year, month, 0).getDate();
  }

  getWeekDay(year: number, month: number, day: number) {
    return new Date(year, month, day).getDay();
  }

  getName(position: number, collection: any) {
    return collection[position];
  }

  async presentCalendarInfo(e: Event, item) {
    if (item.hasVisits || item.hasExpiredDeadlines || item.absent) {
      const componentProps = {
        item: item.eventData,
      };
      const modal = await this._modalController.create({ component: MobileCalendarInfoComponent, componentProps, backdropDismiss: true });

      modal.present();
    }
  }
}
