import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseFormMobileComponent } from '../../../base-components';
import { InputCountItemsRendererComponent } from '../../../general-components/ag-grid/components/cell-renderers';

@Component({
  selector: 'ui-mobile-form-input-text',
  templateUrl: './form-input-text.component.html',
  styleUrls: ['./form-input-text.component.scss'],
})
export class FormInputTextComponent extends BaseFormMobileComponent<string> implements OnInit {
  @Input() matchControlName: string;
  @Input() showButton: boolean;
  @Input() buttonIcon: string;
  @Input() buttonCallback: any;
  @Input() buttonColor: any;
  @Input() callback: (arg) => void;
  @Output() blurEmitter = new EventEmitter();

  public matchControl: FormControl;

  blur($event: Event) {
    this.control.updateValueAndValidity();
    this.blurEmitter.emit($event);
  }
}
