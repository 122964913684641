import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from '@foxeet/utils-modules';
import { IonicModule } from '@ionic/angular';
import { FormValidationMessagesModule } from '../../form-validation-messages/mobile/form-validation-messages.module';
import { FormTripleSwitchComponent } from './form-triple-switch.component';

@NgModule({
  declarations: [FormTripleSwitchComponent],
  imports: [CommonModule, ReactiveFormsModule, FormValidationMessagesModule, I18nModule.forChild(), IonicModule],
  exports: [FormTripleSwitchComponent],
})
export class FormTripleSwitchModule {}
