<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'COMMUNICATIONS_events' | translate }}: {{ item?.eventDateTime | date : 'd/M/y' }}</ion-title>
    <ion-buttons slot="end">
      <ion-button class="button-icon-clear-rounded" (click)="close()" [id]="'AT_close_modal_btn'">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid class="display-grid">
    <ion-row>
      <ion-col>
        <ion-row class="ion-align-items-center">
          <div class="event event--visit"></div>
          <p class="legend-text">{{ 'APPRAISER_visits' | translate }}</p>
        </ion-row>
      </ion-col>
      <ion-col>
        <ion-row class="ion-align-items-center">
          <div class="event event--limit"></div>
          <p class="legend-text">{{ 'APPRAISER_deadlineDate' | translate }}</p>
        </ion-row>
      </ion-col>
      <ion-col>
        <ion-row class="ion-align-items-center">
          <div class="event event--absent"></div>
          <p class="legend-text">{{ 'APPRAISALS_absent' | translate }}</p>
        </ion-row>
      </ion-col>
    </ion-row>
    <ion-item *ngFor="let event of item.events">
      <ion-row class="ion-align-items-center">
        <div *ngIf="event.eventType === eventType.visit" class="event event--visit"></div>
        <div *ngIf="event.eventType === eventType.deadLine" class="event event--limit"></div>
        <div *ngIf="event.eventType === eventType.absent" class="event event--absent"></div>
        <p class="text-info" (click)="goToAppraisal(event)">{{ event.recordId }}</p>
        <p class="text-event" *ngIf="event.appraisalId">{{ 'hour' | translate }}: {{ event.eventDateTime | date : 'HH:mm' }}</p>
        <p class="text-info-absent" *ngIf="!event.appraisalId">{{ 'APPRAISALS_absent' | translate }}</p>
        <p class="text-event" *ngIf="!event.appraisalId && !eventType.absent">
          {{ 'from' | translate }} {{ event.absenceStartDatetime | date : 'HH:mm' }} {{ 'toDate' | translate }} {{ event.absenceFinishDatetime | date : 'HH:mm' }}
        </p>
      </ion-row>
    </ion-item>
  </ion-grid>
</ion-content>
