export const ptLang = {
  cultureCodeLanguage: 'pt-PT',
  languageKey: 'pt',
  icon: 'pt',
  translates: {
    noContent: 'A listagem não tem itens',

    LANG_selectLanguage: 'Selecione um idioma',
    LANG_es: 'Espanhol',
    LANG_en: 'Inglês',
    LANG_pt: 'Português',

    loading: 'A carregar',
    LOGIN_email: 'e-mail',
    LOGIN_password: 'palavra-passe',
    LOGIN_confirmPassword: 'repita a palavra-passe',
    LOGIN_change: 'alterar',
    LOGIN_login: 'iniciar sessão',
    LOGIN_recoverPassword: 'recuperar palavra-passe',
    LOGIN_recoverMessage: 'Insira o seu e-mail e enviaremos as instruções para redefinir a sua palavra-passe.',
    LOGIN_recover: 'recuperar',
    LOGIN_backToLogin: 'voltar',
    LOGIN_emailHasBeenSend: 'As instruções para redefinir a sua palavra-passe foram enviadas para o seu e-mail.',

    FORM_VALIDATION_EMAIL_VALIDATION_ERROR: 'O formato de e-mail não é válido',
    FORM_VALIDATION_EMAIL_VALIDATION_ERROR_ALT: 'O formato de e-mail não é válido',
    FORM_VALIDATION_BUDGET_NUMBER_MASK_ERROR: 'O formato deve ser:  999999-999999',
    FORM_VALIDATION_MAX_LENGTH: 'O comprimento máximo do campo é de:',
    FORM_VALIDATION_MIN_LENGTH: 'O comprimento mínimo do campo é de:',
    FORM_VALIDATION_REQUIRED: 'Campo obrigatório',
    FORM_VALIDATION_PATTERN: 'O formato está errado',
    FORM_VALIDATION_MIN: 'O valor mínimo é:',
    FORM_VALIDATION_MAX: 'O valor máximo é:',
    FORM_VALIDATION_NOT_MATCH: 'os campos não coincidem',
    FORM_VALIDATION_additionHigherThan: 'Soma superior a 100%',
    FORM_VALIDATION_WRONG_INPUT: 'A sua palavra-passe deve ter pelo menos 8 caracteres de comprimento e conter uma combinação de letras minúsculas e maiúsculas, números e caracteres especiais (por exemplo, ! # $).',
    FORM_VALIDATION_NOT_SAME: 'os campos não coincidem',
    FORM_VALIDATION_wrongCredentials: 'Utilizador/palavra-passe incorretos',
    FORM_VALIDATION_MAX_ADDITION: 'A soma ultrapassa:',

    SLIDER_CHIPS_latestOrders: 'Últimos encargos',

    MENU_profile: 'o meu perfil',
    MENU_comparables: 'comparables',
    MENU_profileData: 'os meus dados',
    MENU_documents: 'documentos',
    MENU_invoices: 'faturas',
    MENU_orders: 'encargos',
    MENU_exchange: 'oportunidades',
    MENU_requests: 'colaborações',
    MENU_contactUs: 'Contacte-nos',
    MENU_how_it_works: 'Como funciona?',
    MENU_logOut: 'terminar sessão',
    MENU_schedule: 'Agenda',
    MENU_debug: 'debug',
    MENU_updateDocumentationAndIncompatibilitiesMessage: 'Lembre-se da obrigação de manter a sua documentação e lista de incompatibilidades em conformidade com o artigo 13.º do R.D. 775/1997.',
    MENU_absences: 'disponibilidade / ausências',

    APPRAISER_pageTitle: 'os meus dados',
    APPRAISER_personalData: 'os meus dados',
    APPRAISER_preferences: 'preferências',
    APPRAISER_incompatibilities: 'incompatibilidades',
    APPRAISER_firstName: 'nome',
    APPRAISER_lastName: 'apelidos',
    APPRAISER_nationalIdentityDocument: 'DNI/NIE',
    APPRAISER_phoneNumber: 'telefone',
    APPRAISER_degree: 'grau académico',
    APPRAISER_edit: 'editar',
    APPRAISER_maxDistance: 'distância máxima',
    APPRAISER_receiveNotifications: 'quero receber notificações',
    APPRAISER_notificationPush: 'notificações push',
    APPRAISER_notificationByEmail: 'notificações por e-mail',
    APPRAISER_savePreferences: 'guardar preferências',
    APPRAISER_preferencesMessage: 'Defina as suas preferências para poder receber as Últimas oportunidades!',
    APPRAISER_personalDataSection: 'dados pessoais',
    APPRAISER_notificationsSection: 'notificações',
    APPRAISER_changePassword: 'alterar palavra-passe',
    APPRAISER_password: 'palavra-passe',
    APPRAISER_confirmPassword: 'confirmar palavra-passe',
    APPRAISER_visits: 'Visitas',
    APPRAISER_deadlineDate: 'Data limite',

    APPRAISER_INVOICE_DATA_title: 'dados de faturação',
    APPRAISER_INVOICE_DATA_legalName: 'nome ou denominação social',
    APPRAISER_INVOICE_DATA_nationalIdentityDocument: 'NIF/CIF',
    APPRAISER_INVOICE_DATA_bankAccount: 'conta bancária',
    APPRAISER_INVOICE_DATA_streetType: 'rua',
    APPRAISER_INVOICE_DATA_streetName: 'nome da rua',
    APPRAISER_INVOICE_DATA_streetNumber: 'número',
    APPRAISER_INVOICE_DATA_locality: 'localidade',
    APPRAISER_INVOICE_DATA_province: 'província',
    APPRAISER_INVOICE_DATA_postalCode: 'código postal',
    APPRAISER_INVOICE_DATA_country: 'país',
    APPRAISER_INVOICE_DATA_portal: 'portal',
    APPRAISER_INVOICE_DATA_stairs: 'escada',
    APPRAISER_INVOICE_DATA_gate: 'porta',
    APPRAISER_INVOICE_DATA_irpfPercentage: 'retenção %IRPF na fatura',
    APPRAISER_INVOICE_DATA_billingTaxType: 'tipo de imposto',
    APPRAISER_INVOICE_DATA_ivaPercentage: 'imposto',

    LOCATION_country: 'país',
    LOCATION_province: 'província',
    LOCATION_locality: 'localidade',
    LOCATION_streetNotExists: 'a rua não existe no mapa de ruas',
    LOCATION_streetType: 'tipo de rua',
    LOCATION_streetName: 'nome da rua',
    LOCATION_streetNumber: 'número da rua',
    LOCATION_postalCode: 'código postal',
    LOCATION_portal: 'portal',
    LOCATION_stairs: 'escada',
    LOCATION_floor: 'andar',
    LOCATION_gate: 'porta',
    LOCATION_locationObservations: 'observações',
    LOCATION_block: 'bloco',

    MESSAGES_successPOST: 'Adicionado corretamente',
    MESSAGES_successPUT: 'Editado corretamente',
    MESSAGES_errorPUT: 'Erro ao editar',
    MESSAGES_successDELETE: 'Eliminado corretamente',
    MESSAGES_errorDELETE: 'Erro ao eliminar',
    MESSAGES_successUploadFile: 'Ficheiro carregado corretamente',
    MESSAGES_errorUploadFile: 'Erro ao carregar o ficheiro',
    MESSAGES_errorPOSTLIST: 'Erro ao mostrar a listagem',
    MESSAGES_successConfirm: 'Confirmado corretamente',
    MESSAGES_errorConfirm: 'Erro ao confirmar',
    MESSAGES_successReject: 'Rejeitado corretamente',
    MESSAGES_errorReject: 'Erro ao rejeitar',
    MESSAGES_requiredField: 'Faltam campos necessários',
    MESSAGES_the_action_was_successful: 'A ação foi executada corretamente',
    MESSAGES_successful: 'Bem sucedido',
    MESSAGES_error: 'erro',
    MESSAGES_errorPOST: 'Erro ao adicionar',

    ACTIONS_cancel: 'Cancelar',
    ACTIONS_add: 'Adicionar',
    ACTIONS_edit: 'Editar',
    ACTIONS_delete: 'Eliminar',
    ACTIONS_download: 'Transferir',
    ACTIONS_done: 'Feito',
    ACTIONS_back: 'Voltar',
    ACTIONS_back_list: 'De volta à la lista',
    ACTIONS_save: 'Guardar',
    ACTIONS_skip: 'Pular',
    ACTIONS_confirm: 'Confirmar',
    ACTIONS_close: 'Fechar',
    ACTIONS_reset: 'Reiniciar',
    ACTIONS_reject: 'rejeitar',
    ACTIONS_accept: 'Aceitar',
    ACTIONS_continue: 'Continuar',
    ACTIONS_next: 'próxima',
    ACTIONS_save_selection: 'salvar seleção',

    INCOMPATIBILITIES_firstName: 'nome',
    INCOMPATIBILITIES_lastName: 'apelidos',
    INCOMPATIBILITIES_nationalIdentityDocument: 'DNI/NIE',
    INCOMPATIBILITIES_familyRelationship: 'parentesco',
    WITNESS_OwnComparables: 'Valum comparáveis',

    APPRAISAL_BASIC_FILTERS: {
      recordIdContains: 'N.º do processo',
      executionToolId: 'N.º da referência interna',
      externalRecordId: 'N.º da referência externa',
    },
    APPRAISAL_STATUS_FILTERS: {
      currentWorkflowSteps: 'Estado',
      purposes: 'Finalidade',
      visitTypes: 'Tipo de visita',
    },
    APPRAISAL_LIMIT_DATES_FILTERS: {
      scheduleVisitLimit: 'Limite para agendar visita',
      startVisitLimit: 'Limite para iniciar visita',
      finishVisitLimit: 'Limite para concluir visita',
      sentReportLimit: 'Limite para enviar relatório',
    },
    APPRAISAL_INCIDENTS_FILTERS: {
      withIncidentsNotResolved: 'Com incidentes não resolvidos',
      withIncidents: 'Com incidentes',
    },
    APPRAISAL_INVOICE_FILTERS: {
      isInConfirmedInvoice: 'Possui fatura confirmada',
    },
    EXCHANGE_FILTERS: {
      distanceBetweenPointMaxMeters: 'Distância máxima',
      exchangeMinFee: 'Tarifa mínima',
      exchangeFinishMaxDateTime: 'Limite para enviar relatório',
    },
    FILTERS: {
      basicFilters: 'Filtros básicos',
      exchangeFilters: 'Filtros de oportunidades',
      appraisalState: 'Estado da avaliação',
      dateLimits: 'Limites de datas',
      incidents: 'Incidentes',
      invoice: 'Fatura',
      addedDate: 'Data de criação',
      apply: 'Aplicar',
      reset: 'Reiniciar',
      requestState: 'Estado da solicitação',
      typologyFilters: 'Tipologia',
      locationFilters: 'Localização',
      featuresFilters: 'Características',
      appraisal: 'relatório',
    },
    DATES: {
      from: 'Desde',
      to: 'Até',
      visitScheduleDeadlineDateTime: 'limite para agendar',
      visitScheduleDateTime: 'data de visita',
      appraiserAllDataSentDeadlineDateTime: 'limite para enviar relatório',
      addedDateTime: 'adicionado',
      solvedDateTime: 'resolvido',
      expirationDateTime: 'termo',
      date: 'data',
      allDay: 'qualquer momento do dia',
      hour: 'hora',
      chooseADate: 'Selecione uma data',
      chooseOtherDate: 'Ou especifique uma nova data para a visita',
      confirmDate: 'Confirmar data e hora',
      addProposalDate: 'Adicionar data possível para a visita',
      obtainedDatetime: 'Data de obtenção',
    },
    APPRAISALS: 'encargos',
    APPRAISAL: {
      searchResult: 'Resultado da pesquisa',
      addIncident: 'Adicionar incidente',
      addComment: 'Adicionar comentário',
      editComment: 'Editar comentário',
      finished: 'Concluídas',
      sentToRevision: 'Enviadas para revisão',
      distance: 'Distância',
      expiresAt: 'Expira em',
      lastOrders: 'Últimos encargos',
      scheduleVisitPending: 'A aguardar agendamento',
      visitPending: 'visita pendente',
      visitPendingFinish: 'Visita pendente a finalizar',
      sentPending: 'a aguardar envio',
      rejected: 'rejeitadas',
      incidents: 'Incidentes',
      detail: 'detalhe',
      reports: 'relatórios',
      report: 'relatório',
      board: 'mural',
      info: 'info',
      requestElements: 'bens a avaliar',
      purpose: 'finalidade',
      visitType: 'tipo de visita',
      mínimoTypeVisitRequired: 'Tipo de visita mínima necessária',
      deadlines: 'prazos',
      customer: 'cliente',
      prescriptor: 'prescritor',
      customers: 'clientes',
      prescriptors: 'prescritores',
      contact: 'contacto',
      incidentResolution: 'resolução',
      revision: 'Revisão',
      request: 'Solicitação',
      assets: 'Ativos',
      clusters: 'Agrupamentos',
      comment: 'comentário',
      incidentDescription: 'Descrição',
      incidentTypes: 'tipos de incidente',
      scheduleVisit: 'Agendar visita',
      editScheduleVisit: 'Editar data de visita',
      startVisit: 'Iniciar visita',
      startVisitMessage: 'Vai iniciar a visita. Continuar?',
      finishVisit: 'Concluir visita',
      finishVisitMessage: 'Vai concluir a visita. Continuar?',
      sendToRevision: 'Enviar processo para revisão',
      sendToRevisionMessage: 'Vai enviar o processo para revisão Continuar?',
      visitProposedDates: 'Datas propostas para a visita',
      scheduleVisitSuccess: 'Visita agendada corretamente',
      scheduleVisitError: 'Erro ao agendar a visita',
      visitDate: 'Data da visita',
      limitScheduleDateTime: 'limite para agendar visita',
      limitSendDataDateTime: 'limite para enviar relatório',
      successSendToRevision: 'Processo enviado corretamente para revisão',
      successFinishStarted: 'Visita concluída corretamente',
      successVisitStarted: 'Visita iniciada corretamente',
      sureToSelectDate: 'A data a selecionada está fora do prazo.',
      importReport: 'Importar relatório de',
      confirmationHeader: 'Tem a certeza?',
      confirmationMessage: 'O relatório foi copiado com sucesso, com exceção dos documentos e das secções de fotografias. Reveja e modifique os dados necessários e inclua a documentação atualizada.',
      scheduleDeadLine: 'limite para agendar visita',
      exchangeFinish: 'limite para enviar relatório',
      idufir: 'C. R. U. (IDUFIR)',
      land: 'Solo',
      propertyRegistryName: 'registo da propriedade',
      volume: 'volume',
      book: 'livro',
      paper: 'folha',
      inscription: 'inscrição',
      fee: 'honorários',
      executionMode: 'modo de execução',
      executedByIvForce: 'ivforce',
      executedByExternalTool: 'ferramenta externa',
      registeredPropertyNumber: 'Propriedade',
      section: 'Folha',
      appraisalOrderType: 'tipo de encargo',
    },
    APPRAISAL_REPORT: {
      openingDate: 'data de abertura',
      closingDate: 'data de encerramento',
      lastEdition: 'Última edição',
      inProgress: 'Em processamento',
      closed: 'Fechado',
      version: 'Versão',
      importAssetStructure: 'Importar estrutura para ativos',
      commonData: 'Dados comuns do relatório',
      clusterToValuate: 'Agrupar para avaliar',
      completeForm: 'Preencher formulário',
      checkAllAssets: 'Selecionar todas as unidades',
      clusterValuations: 'Agrupar avaliações',
      valuateTerrains: 'Avaliar terrenos',
      units: 'unidades',
      selectStep: 'Selecionar um passo',
      requiredPercent: 'de solicitados',
      completedPercent: 'de concluído',
      addAsset: 'Adicionar ativo',
      seeValuationResume: 'Ver resumo das avaliações',
      valuationResume: 'Resumo das avaliações',
    },
    ASSET_TREE: {
      addAnnex: 'Adicionar anexo',
      duplicate: 'Duplicar',
      delete: 'Eliminar',
      cancel: 'Cancelar',
      makePrincipal: 'Tornar principal',
      extractAsset: 'Extrair da estrutura',
      deleteAssetConfirm: 'Eliminar',
      deleteAssetCancel: 'Cancelar',
      deleteAssetSuccess: 'Ativo eliminado corretamente',
      deleteAssetError: 'Erro ao eliminar ativo',
      deleteAsset: 'Eliminar ativo',
      deleteAssetMessage: 'Quando eliminar o ativo, também eliminará todos os anexos ligados. Continuar?',
      recalculateValuations: 'Recalcular avaliações',
      notCompleted: 'Não concluído',
      unComplete: 'Incompleto',
      complete: 'Completo',
      recalculateWorkPlan: 'Recalcular estado da obra',
      hasValuations: 'Você não pode agrupar este ativo, pois ele tem métodos de avaliação associados.',
      onlyClusterSameCategory: 'Não podrás agrupar elementos mezclados, suelos solo se puede agrupar con suelos, elementos terminados, con elementos terminados y explotaciones económicas con explotaciones económicas. No se podrán agrupar elementos No terminados.',
      selectAssetsToCluster: 'Selecione as unidades que deseja agrupar:',
    },
    STEPS: {
      location: 'Localização',
      identification: 'Identificação',
      typology: 'Tipologia',
      surfaces: 'Superfícies',
      property_features: 'Características da propriedade',
      localityAndEnvironmentReport: 'Localidade, zona limítrofe e mercado',
      terrainFeatures: 'Características do terreno',
      buildingTerrain: 'Descrição do terreno',
      buildingFeatures: 'Características do edifício',
      assetFeatures: 'Características do imóvel',
      urbanisticSituation: 'Situação urbanística',
      possession: 'Posse',
      checks: 'Verificações',
      valuations: 'Avaliações',
      conditioningWarningsObservations: 'Condicionantes, advertências e observações',
      photos: 'Fotos',
      documentation: 'Documentação',
      surfacesAndAssetFeatures: 'Superfícies e características do imóvel',
      surface: 'Superfície',
      LocalityEnvironmentAndBuildingFeatures: 'Características do edifício, localidade, zona límitrofe e mercado',
      buildingState: 'Estado da obra',
      environment: 'Zona limítrofe',
    },
    DOCUMENTS: {
      addDocument: 'Adicionar documento',
      document: 'documento',
      documents: 'documentos',
      expirationDate: 'data de validade',
      addedSuccess: 'O documento foi carregado corretamente',
      deletedSucces: 'O documento foi apagado corretamente',
      deleteThisDocument: 'Tem a certeza de que deseja eliminar este documento?',
      expirationDateTime: 'Data de validade',
    },
    error: 'Erro',
    REPORT_ASSET_TREE_FILTER: {
      registryNumber: 'Registo da propriedade',
      idufir: 'C.R.U. (IDUFIR)',
      cadastralReference: 'Referência cadastral',
      address: 'Endereço',
      state: 'Estado',
      use: 'Uso',
      typology: 'Tipologia',
      subTypology: 'Subtipologia',
    },
    INVOICE: {
      invoices: 'faturas',
      actionSuccess: 'A ação foi executada corretamente',
      actionError: 'Não foi possível realizar a ação',
      rejectInvoice: 'Recusar fatura',
      reasonRejectInvoice: 'Escreva o motivo',
      subtotal: 'subtotal',
      total: 'total',
      irpf: 'irpf',
      iva: 'iva',
      taxPercentageText: 'Base tributável para',
      invoiceNumberAlertTitle: 'Introduza o número da fatura',
      invoiceNumber: 'N.º da fatura',
    },
    REPORT_ASSET: {
      dataSource: 'Origem dos dados',
      observations: 'Observações',
      mainElements_Structure: 'Estrutura',
      mainElements_Deck: 'Cobertura',
      mainElements_Enclosure: 'Recintos',
      mainElements_Foundation: 'Fundação',
      constructionYear: 'Ano de construção',
      lastRehabilitationYear: 'Ano de reabilitação',
      qualityOfBuilding: 'Qualidade das edificações',
      conservationState: 'Estado de conservação',
      buildingUse: 'Uso',
      totalFloorsBellowGround: 'Pisos abaixo do solo',
      totalFloorsAboveGround: 'Pisos acima do solo',
      numberOfBuildingAccesses: 'Acessos do edifício',
      currentStateOfBuilding: '',
      streetCategory: 'Categoria da rua',
      surveillance: 'Vigilância',
      hasSwimmingPool: 'Piscina',
      hasSportsZone: 'Zona desportiva',
      hasGreenArea: 'Zonas verdes',
      hasElevator: 'Elevador',
      totalPlotSurface: 'Superfície da parcela',
    },
    VALUATIONS: {
      observationsOcupation: 'Comentários relacionados com a ocupação',
      observationsNetIncome: 'Comentários sobre o rendimento líquido / Informações de estimativa de pagamento',
      groundRepercussionError: 'Método de custo',
      necessaryConstructionExpensesPercentageRequired: 'Preencher ""Cálculo em função do progresso da obra""‘',
      calculationsInProgressWorkPlanTitle: 'Construção - Custos e despesas',
      constructionCostsTitle: 'Custos de construção',
      calculationsInProgressWorkPlanConstructionCostTotal: 'Custo de construção total',
      calculationsInProgressWorkPlanProgressPercentage: 'Progresso da obra %',
      calculationsInProgressWorkPlanConstructionCostCurrent: 'Custo de construção - Atual',
      calculationsInProgressWorkPlanNecessaryExpenses: 'Despesas necessárias %',
      calculationsInProgressWorkPlanExpensesProgressPercentage: 'Progresso dos gastos %',
      calculationsInProgressWorkPlanAdoptedNecessaryExpenses: 'Despesas necessárias adotadas %',
      calculationsInProgressWorkPlanNecessaryExpensesCurrent: 'Despesas necessárias - Atual',
      calculationsInProgressWorkPlanManualEditing: 'Editar percentagem do progresso nas despesas',
      calculationsInProgressWorkPlanFormMessage: 'Os valores de construção são calculados com base na percentagem de progresso da obra. O valor de custo provém do distribuído na estimativa do Estado de obra.',
      calculationsInProgressWorkPlanLink: 'Cálculo em função do progresso da obra',
      costMethodRepercussionCalculateMessage: 'Se alterar o modo de introdução da repercussão, perderá os dados incluídos até agora, deseja continuar?',
      summaryTotalValuationsRecalculationMessage: 'As características do ativo foram modificadas. Tem de recalcular as avaliações para continuar.',
      futureValuationTotalValue: 'Avaliação total H.E.T',
      currentValuationTotalValue: 'Avaliação total atual',
      valorationMethodAdopted: 'Método de avaliação',
      insuranceByRoyalDecree: 'Seguro de acordo com o R.D.',
      insuranceByEco: 'Seguro de acordo com a ECO',
      valuationTotalValue: 'Avaliação total',
      insuranceValorationTypesDropdown: 'Cálculo do valor do seguro relativo a:',
      proposalValue: 'Valor proposto',
      documents: 'Documentação',
      totalProposalValue: 'Valor proposto total',
      observations: 'Observações',
      surface: 'Superfície',
      selectSurfaceTocontinue: 'Selecione uma superfície para continuar',
      surfaceCommons: 'Superfície construída com partes comuns',
      surfaceBuiltWithoutCommons: 'Superfície construída sem partes comuns',
      surfaceUseful: 'Superfície útil',
      cadastral: 'Cadastral',
      checked: 'Verificada',
      registered: 'Registada',
      usefulRegistralSurface: 'Superfície útil registada',
      usefulCheckedSurface: 'Superfície útil verificada',
      usefulQualifiedSurface: 'Superfície útil verificada certidão de qual.',
      ground: 'Solo',
      repercussion: 'Repercussão',
      method: 'Método',
      necessaryExpenses: 'Despesas necessárias',
      totalMarketValue: 'Valor total de mercado',
      construction: 'Construção',
      VRB: 'VRB',
      editPercentages: 'Editar percentagens',
      physicalBuildingDeprecation: 'Dep. física do edifício',
      physicalElementDeprecation: 'Dep. física do elemento',
      functionalDeprecation: 'Dep. funcional',
      VRN: 'VRN',
      residualStaticCostTitle: 'Residual estático simplificado',
      residualStaticCostMarketValueUnitPrice: 'Valor de mercado ({{currency_per_surfaceUnit}})',
      residualStaticCostConstructionCostUnitPrice: 'Custos de contrução ({{currency_per_surfaceUnit}})',
      residualStaticCostConstructionExpensesUnitPrice: 'Despesas de contrução ({{currency_per_surfaceUnit}})',
      residualStaticCostFinancialExpensesPercentage: 'Despesas financeiras (%)',
      residualStaticCostMarketingExpensesPercentage: 'Despesas de comercialização (%)',
      residualStaticCostDeveloperProfitPercentage: 'Benefícios do promotor (%)',
      residualStaticCostKCoeficient: 'K (Coeficiente para passar para novo)',
      total: 'Total',
      qualifiedSurface: 'Superfície classificada',
      legalMaximumValues: 'Valores do máximo legal',
      legalMaximumValue: 'Valor máximo legal',
      legalMaximumUnitValues: 'Valor unitário máximo legal',
      legalMaximumTotalValue: 'Valor total máximo legal',
      legalMaximumGroundValue: 'Valor máximo legal do solo',
      groundValueLimit: 'Limitação do valor do solo',
      REA_legalMaximum: 'REA (máximo legal)',
      REA_costMethod: 'REA (método de custo)',
      valueTypes: 'Tipos de valor',
      moreInfo: 'Mais informações',
      marketValue: 'Valor de mercado',
      contractualData: 'Dados contratuais',
      contractStartDate: 'Data de início do contrato',
      contractFinishDate: 'Data de extinção do contrato',
      nextChargeDate: 'Data da próxima cobrança',
      economicalData: 'Dados económicos',
      monthlyGrossIncome: 'Renda bruta mensal',
      monthlyExpenses: 'Despesas mensais',
      monthlyNetIncome: 'Renda líquida mensal',
      updateType: 'Tipo de atualização',
      updateRatePercent: 'Taxa de atualização',
      monthlyEquivalentUpdateRatePercent: 'Taxa de atualização mensal equivalente',
      reversionValue: 'Valor da reversão',
      capitalGainPercent: 'Mais-valias/menos-valias do solo',
      updatedValues: 'Valores atualizados',
      updatedIncomes: 'Rendas atualizadas',
      updatedReversion: 'Reversão atualizada',
      updatedTotal: 'Total atualizado',
      marketNetIncome: 'Renda líquida mercado',
      euroYearSuffix: '{{currency}}/ano',
      euroMonthSuffix: '{{currency}}/mês',
      euroM2MonthSuffix: '({{currency_per_surfaceUnit}})/mês',
      activeNetIncome: 'Renda líquida ativa',
      currentIncome: 'Renda atual',
      date: 'Data',
      incomes: 'Renda',
      yearly: 'Anual',
      monthly: 'Mensal',
      marketEconomicRentalData: 'Dados económico de renda do mercado',
      homogeinizedValue: 'Valor homogeneizado',
      deprecatedHomogeinizedValue: 'Valor homogeneizado depreciado',
      comparable: 'Comparável',
      adjustComparison: 'Comparação ajustada',
      witnesses: 'Testemunhas',
      witness: 'Testemunha',
      witnessList: 'Lista de testemunhas',
      distance: 'Distância',
      surfaceM2: 'Superfície ({{surfaceUnit}})',
      homogeinizedCoefficient: 'Coef. Homogeinização',
      weight: 'Peso',
      marketValueEuroPerM2: 'Valor de mercado ({{currency_per_surfaceUnit}})',
      marketValueEuroPerM2Month: 'Valor de mercado ({{currency_per_surfaceUnit}}/mes)',
      finalValueEuroPerM2: 'Valor final ({{currency_per_surfaceUnit}})',
      finalValueEuroPerM2Mont: 'Valor final ({{currency_per_surfaceUnit}}/mes)',
      noWitnessAdded: 'Não há testemunhas adicionadas',
      desoccupation: 'Desocupação',
      latePayment: 'Morosidade',
      annualCapex: 'Capex anual',
      netIncome: 'Renda líquida',
      unitaryEuroM2Month: 'Unitária ({{currency_per_surfaceUnit}}/mes)',
      monthlyEuroMonth: 'Mensal ({{currency}}/mês)',
      annualEuroYear: 'Anual ({{currency}}/ano)',
      idlenessMonths: 'Meses de desocupação',
      latePaymentMonths: 'Meses de morosidade',
      IPC: 'IPC',
      clusterData: 'Dados do agrupamento',
      name: 'Nome',
      adoptedSurface: 'Superfície adotada',
      reformLastYear: 'Ano de remodelação',
      use: 'Uso',
      typology: 'Tipologia',
      subtypology: 'Subtipologia',
      censusSection: 'Secção censual',
      buildingInfo: 'Informação do edifício',
      mainUse: 'Uso predominante',
      constructionYear: 'Ano de construção',
      totalLegalMaximumValue: 'Valor total do máximo legal',
      createCluster: 'Criar agrupamento',
      clustersValuations: 'Avaliação de agrupamentos',
      terrainsValuations: 'Avaliação de terrenos',
      clusterItems: 'Unidades do agrupamento',
      clusterSurface: 'Superfície do agrupamento',
      clusterDeleteMessage: 'Tem a certeza de que deseja eliminar este agrupamento?',
      errors: {
        title: 'Erros',
        buildingUseRequired: 'Falta o uso do edifício',
        buildingYearRequired: 'Falta o ano de construção do edifício',
        costMethodRequired: 'O método de custo é necessário',
        residualStaticAbr: 'É necessário preencher o método estático residual no método de custo',
        atLeastOneSurfaceWithValue: 'É necessária pelo menos uma superfície com valor',
        groundRepercussionError: 'Repercussão do solo',
        clusterAssetsWithoutSelectedSurfaces: 'É necessário adicionar as superfícies na seção Superfícies de cada ativo e selecioná-las na seção Unidades.',
      },
      recalculateMethod: 'Recalcular métodos',
      recalculate: 'Recalcular',
      current: 'Avaliação atual',
      future: 'Avaliação H.E.T.',
      homogeneousCoefficientType: 'Tipo de homogeinização',
      confirmCoeficientChange: 'Se alterar o tipo de homogeneização, perderá os dados relacionados e incluídos até agora, deseja continuar?',
      marketPriceUnitValue: 'Valor de mercado ({{currency_per_surfaceUnit}})',
      constructionRehabilitation: 'Ano construção / reabilitação',
      surfaceTotal: 'Superfície ({{surfaceUnit}})',
      elevatorsCount: 'Nº de Elevadores',
      homogeneousCoefficient: 'Coef. homog. global',
      adoptedUnitValue: 'Valor final ({{currency_per_surfaceUnit}})',
      situation: 'Coef. Situação',
      orientation: 'Coef. Orientação',
      state: 'Coef. Estado',
      height: 'Coef. Altura',
      coefSurface: 'Coef. Superfície',
      morphology: 'Coef. Morfologia',
      polyvalence: 'Coef. Polivalência',
      accessibility: 'Coef. Acessibilidade',
      antiquity: 'Coef. Antiguidade',
      others: 'Coef. Outros',
      header: 'Editar coeficientes de homogeinização',
      witnessAddedDate: 'Data de criação da testemunha',
      witnessAddedDateCreation: 'Data de criação',
    },
    TYPOLOGY: {
      hasActiveCultivation: 'tem cultivo ativo?',
      use: 'uso',
      residenceType: '1.ª/2.ª habitação',
      typology: 'tipologia',
      subTypology: 'subtipologia',
      state: 'estado do ativo',
      conservationState: 'estado de conservação',
      legalSituation: 'situação legal',
      hasEnergeticCertificate: 'possui certificado energético?',
      reformLastYear: 'ano de remodelação',
      energeticCertificateGrade: 'tipos de certificados energético',
      expedient: 'processo',
      protectionTimeLimit: 'prazo de proteção',
      provisionalQualificationDateTime: 'data de classificação provisória',
      definitiveQualificationDateTime: 'data de classificação definitiva',
      isVPO: 'É VPO',
      workState: 'Estado da obra',
      borrowersHabitualResidence: 'Residência habitual do mutuário',
      isEconomicExploitation: 'É uma exploração econômica?',
      economicExploitationObservations: 'Descrição da fazenda',
    },
    SURFACES: {
      surface: 'Superfície ({{surfaceUnit}})',
      surfaces: 'superfícies',
      cadastralSurfaces: 'cadastral',
      totalUsefulSurface: 'Útil ({{surfaceUnit}})',
      totalBuiltSurfaceWithoutCommonSurfaces: 'construída sem partes comuns ({{surfaceUnit}})',
      totalBuiltSurfaceWithCommonSurfaces: 'construída com partes comuns ({{surfaceUnit}})',
      checkedSurfaces: 'verificada',
      registeredSurfaces: 'registada',
      observations: 'observações',
      totalPlotSurface: 'Parcela ({{surfaceUnit}})',
      workPlanTotalSurface: 'Superfície total da obra ({{surfaceUnit}})',
    },
    NEW_ASSET: {
      addAsset: 'Adicionar ativo',
      back: 'Voltar à listagem e ativos',
      byLocation: 'por endereço',
      byCadastralReference: 'Por referência cadastral',
      locateAgain: 'Voltar a localizar',
      locate: 'Localizar',
      addManually: 'Introduzir manualmente',
      cadastralReference: 'Referência cadastral',
      localization: 'localização',
      typology: 'tipologia',
      identification: 'identificação',
      surfaceAndBuildingInformation: 'superfície e informação do ativo',
      surface: 'superfície',
      constructionYear: 'Ano de construção',
      chooseACadastralReference: 'Escolha uma referência cadastral',
      chooseIdealistaComparables: 'Escolha a testemunha que deseja usar',
    },
    IDENTIFICATION: {
      cadastralReference: 'Referência cadastral',
      cadastralReferenceContainerBuilding: 'Referência cadastral do edifício',
      idufir: 'C.R.U (IDUFIR)',
      registeredPropertyNumber: 'Registo da propriedade',
      propertyRegistrationName: 'Registo da propriedade',
      volume: 'Volume',
      book: 'Livro',
      paper: 'Folha',
      section: 'Secção',
      inscription: 'Inscrição',
      observations: 'Observações',
    },
    LOCATION_ENVIRONMENT_MARKET: {
      dataSource: 'origem dos dados',
      editAutomaticInformation: 'editar informação automática',
      environmentPersonalOpinion: 'opinião técnica sobre a zona limítrofe do imóvel',
      environment: 'zona limítrofe',
      location: 'localidade',
      market: 'mercado',
      featuresMarket: 'características do mercado imobiliário comparável',
      marketMortgageValueDifferences: 'diferenças do valor de mercado e valor hipotecário',
      offer: 'oferta',
      demand: 'procura',
      currentPricSaleRental: 'intervalos de preços atuais de venda/arrendamento',
      demandRevaluationExpectations: 'expectativas oferta-procura e revalorização',
      jobOccupation: 'ocupação laboral',
      characteristicAntiquity: 'antiguidade característica',
      architecturalHomogeneity: 'homogeneidade arquitetónica',
      infrastructures: 'infraestruturas',
      equipmentAndServices: 'equipamentos e serviços',
      communications: 'comunicações',
      parkings: 'parques de estacionamento',
      buildingDevelopment: 'desenvolvimento edificatório',
      renovation: 'renovação',
      localityTypeOfCore: 'tipo de núcleo',
      localityPopulation: 'população',
      localityPopulationEvolution: 'evolução recenta da população',
      localityUnemploymentRate: 'desemprego',
      localityAvgAnnualIncome: 'rendimento médio anual per capita disponível',
      censusSectionTypeOfCore: 'tipo de núcleo',
      automaticDataWarning: 'Ao guardar o formulário serão recuperados os dados com base na localização, perdendo os dados editados manualmente.',
      environmentDescription: 'Descrição da zona limítrofe',
      localityMainFeatures: 'Principais características da localidade',
      environmentSignificance: 'Significância da zona limítrofe',
      locationComparedToSameMunicipality: 'Localização do imóvel em comparação com outros do mesmo município',
      locationComparedToSameZone: 'Localização do imóvel em comparação com outros da mesma zona',
      locationComparedToSameBuildingElements: 'Localização do imóvel dentro do edifício em comparação com outros elementos do mesmo edifício',
      pendingInfrastructuresPercentage: 'Percentagem de infraestruturas pendentes',
      environmentConsolidationDegreePercentage: 'Percentagem do grau de consolidão da zona limítrofe',
    },

    APPRAISAL_INCIDENTS_FILTER: {
      withIncidentsNotResolved: 'Con incidencias sin resolver',
      withIncidents: 'Con incidencias',
    },

    DOCUMENTOS: {
      addDocument: 'adicionar documento',
      documento: 'documento',
      documentos: 'documentos',
      expirationDate: 'data de expiração',
      AddedSuccess: 'O documento foi carregado com sucesso',
      deleteSucces: 'O documento foi deletado com sucesso',
      deleteThisDocument: 'Tem certeza de que deseja excluir este documento?',
      expirationDateTime: 'Data de expiração',
    },

    APPRAISAL_REQUESTS: {
      requests: 'solicitações',
      contactUsTitle: 'aumento dos benefícios dos contribuintes',
      contentTitle: 'seus orçamentos e pagamentos com um único clique',
      list1: 'cotar seus pedidos de avaliação em apenas duas etapas',
      list2: 'conhecer a proposta econômica imediatamente',
      list3: 'receba em seu email um link de pagamento e o resumo do seu pedido',
      list4: 'seu cliente terá apenas que acessar o link de pagamento e poderá escolher o método que mais se adequa às suas preferências: cartão de crédito, transferência bancária, etc.',
      list5: 'você não precisa se preocupar com mais nada. Seu pedido já está em andamento!',
      footer: 'nós ajudamos você a otimizar e tornar seu trabalho ainda mais lucrativo.',
      contactUs: 'fale conosco!',
      readAndAcceptTerms: 'Li e aceito',
      generalConditions: 'as condições gerais de venda',
      taxError: 'A taxa deve ser maior ou igual à taxa mínima proposta',
      totalPriceWithoutTaxes: 'preço (sem impostos)',
      withVisit: 'Eu faço uma visita',
      withReport: 'executar relatório',
      observations: 'observações',
      isEnterprise: 'é uma empresa',
      name: 'nome',
      lastName: 'sobrenome',
      nationalIdentityDocument: 'DNI/NIE',
      phone: 'telefone',
      email: 'e-mail',
      orderType: 'tipo de pedido',
      purpose: 'finalidade',
      visitType: 'tipo de visita',
      witECO: 'ECO 805/2003',
      address: 'endereço',
      postalCode: 'código postal',
      province: 'província',
      localidade: 'localidade',
      locality: 'dados de propriedade',
      addAsset: 'adicionar ativo',
      impossibleToMap: 'Não foi possível mapear as informações cadastrais, alguns dados devem ser preenchidos manualmente',
      taxMessage: '*As taxas propostas são editáveis ​​desde que o valor editado seja superior ao proposto.',
      cancelAddAsset: 'cancelar',
      year: 'ano',
      priceRange: 'faixa de valor estimado',
      taxes: 'taxa',
      owner: 'dados do proprietário da avaliação',
      ownerDataMessage: 'Relembramos que, como solicitante da avaliação, você é responsável por informar ao seu cliente que seus dados serão transferidos para o Instituto de Valoraciones S.A. para executar o serviço contratado',
      appraisalRequestFeatures: 'características de atribuição',
      visitProposals: 'Datas de visita',
      invoiceData: 'dados de faturamento',
      copyOwnerData: 'Copiar dados do proprietário',
      saveRequests: 'Salvar solicitação',
      addRequest: 'adicionar solicitação',
      requestDetail: 'Detalhe da solicitação',
      hasAppraisals: 'Tem avaliações',
      showAppraisals: 'Ver Avaliações',
      relatedAppraisalList: 'Lista de avaliações relacionadas',
      cif: 'CIF',
      rateMessage: '* As tarifas são calculadas com base no tipo e valor do imóvel. Verifique se a tipologia identificada corresponde à real. Caso contrário, insira a propriedade manualmente.',
      acceptTipologyCommitmentMessage: 'Aceito que o orçamento seja revisto pelo Valuation Institute para verificar se os imóveis incluídos foram correctamente identificados quanto à sua tipologia. Caso se verifique que não correspondem à tipologia real, este orçamento não será válido e o cliente deverá pagar a diferença para iniciar a encomenda.',
      visitData: 'dados de contato para a visita',
      visitName: 'nome sobrenome / nome da empresa',
      visitPhone: 'telefone',
      visitEmail: 'e-mail',
      visitObservations: 'observações',
      hasRequestElements: 'Faltam elementos para adicionar ao pedido',
      requestElementsArrayIsValid: 'Alguns dos elementos estão faltando dados para preencher',
      proposedDatesFormArrayIsValid: 'Você precisa verificar as possíveis datas de visita',
      finalidadeSNB: 'Propósito',
    },

    REPORT_ASSET_PROPERTY_FEATURES: {
      installations: 'instalações',
      stays: 'permanece',
      hasHotWater: 'água quente',
      hasHeating: 'aquecimento',
      hasAirConditioning: 'ar condicionado',
      hasGas: 'gas',
      hasPlumbingAndSanitaryEquipment: 'encanamento e aparelhos sanitários',
      hasIntercom: 'intercom',
      hasSecurity: 'security',
      hasPhone: 'telefone',
      hasSanitation: 'saneamento',
      externalWoodWork: 'carpintaria exterior',
      facilidadeObservações: 'observações',
      pavementType: 'pavement type',
      wallType: 'tipo de parede',
      roofType: 'roof type',
      internalWoodWork: 'carpintaria interior',
      addStay: 'adicionar estadia',
      editStay: 'edit stay',
      stayType: 'tipo de estadia',
      totalSurface: 'superfície',
      generate: 'gerar',
      distribution: 'distribuição',
      stayResidentialMessage: 'deve haver pelo menos um quarto e um banheiro',
      deleteThisStay: 'Tem certeza que deseja deletar esta sala?',

      propertySize: 'Tamanho da propriedade',
      license: 'Licença',
      polivalentUse: 'Uso polivalente',
      productAnalysis: 'Análise de produto',

      hasEquippedKitchen: 'Cozinha equipada',
      hasBuiltInWardrobes: 'guarda-roupas embutidos',
      hasAlarmSystem: 'Sistema de alarme',
      hasClosedSecurityCircuit: 'Circuito fechado de segurança',
      hotWaterType: 'Água quente',
      heatingType: 'Tipo de aquecimento',

      localFeatures: 'Características locais',
      facade: 'Fachada (m)',
      depth: 'Background (m)',
      clearanceHeight: 'Altura livre (m)',
    },
    WITNESS_DETAIL: {
      supplierInfo: 'Información del proveedor',
      cadastralInformation: 'Información catastral',
      cadastralReference: 'Referencia catastral',
      cadastralSurface: 'Superficie catastral',
      builtSurface: 'Superficie construida',
      plotSurface: 'Superficie de parcela',
      features: 'Características',
      offerValue: 'Valor de oferta',
      reductionNegotiation: 'Reduc. / Negociación',
      offerType: 'Tipo de oferta',
      sourceType: 'Tipo de origen',
      crawledSource: 'Origen del testigo',
      providerUrl: 'URL del proveedor',
      contactInfo: 'Número de contacto',
      constructionYear: 'Año de construcción',
      reformLastYear: 'Año de reforma',
      qualityOfBuilding: 'Calidad de la construcción',
      conservationState: 'Estado de conservación',
      bathroomCount: 'Número de baños',
      bedroomCount: 'Número de habitaciones',
      hasPool: 'Piscina',
      hasSportsZone: 'Zona deportiva',
      hasGreenArea: 'Zonas verdes',
      hasHeating: 'Calefacción',
      hasAirConditioning: 'Climatización',
      hasElevator: 'Ascensor',
      witnessDetail: 'Detalles del testigo',
      hasPaving: 'Paving',
      hasPublicLighting: 'Iluminação pública',
      hasWaterSupply: 'Abastecimento de água',
      hasSanitationNetwork: 'Rede de saneamento',
      hasTelephoneNetwork: 'Rede telefônica',
      hasPipedGasNetwork: 'Rede de gás canalizado',
      buildeableSurface: 'Superfície edificável',
      observations: 'Observações',
      mainUse: 'Uso principal',
      hasFlatTerrain: 'Nível térreo',
      hasWarehouse: 'Armazém',
      hasActiveCrop: 'Cultivo ativo',
      hasRolledAccess: 'Acesso rodoviário',
      hasWaterDistribution: 'Distribuição de água',
    },

    URBAN_SITUATION: {
      dataSource: 'fonte de dados',
      currentPlanning: 'planejamento atual',
      description: 'descrição',
      currentPlanningAdequacy: 'adaptação à abordagem atual?',
      observations: 'observações',
      subjectCorrespondingManagementInitiative: 'Assunto a quem corresponde a iniciativa de gestão',
      modeAndManagementSystem: 'Sistema e modo de gerenciamento',
      urbanBurdens: 'Encargos urbanos (transferências, custos de urbanização, obrigações adquiridas, etc.)',
      pendingProcedures: 'Procedimentos pendentes para terreno finalista',
      executionDeadlinesAndConsequences: 'Prazos de execução previstos para o ordenamento urbano do terreno. Consequências em caso de não conformidade ',
      expropiation: 'Expropriação. Situação do processo de expropriação. Critérios legais aplicáveis ​​à sua avaliação',
      buildability: 'Capacidade de construção',
      characteristicUse: 'Uso característico',
      compatibleUses: 'Usos compatíveis',
      prohibitedUses: 'Usos Proibidos',
      maxHeight: 'Altura máxima',
      occupation: 'Ocupação',
      setBacks: 'Retrocessos',
      minimunPlot: 'Gráfico mínimo',
      exploitation: 'Exploração',
      scopeSurface: 'Scope surface',
      managment: 'Gestão urbana',
      params: 'Parâmetros urbanos',
      urbanisticQualificationAndManagement: 'Qualificação e gestão urbanística',
      urbanisticParameters: 'Urbanistic Parameters',
      isAdequatedToCurrentPlanning: 'Adequação à abordagem atual?',
    },

    POSSESSION: {
      ocupationState: 'estado de ocupação',
      occupationType: 'ocupante',
      hasNonexistenceLeasesDocument: 'documento de inexistência de arengas',
      hasContract: 'Existe um contrato?',
      tenureType: 'tipo de posse',
      name: 'primeiro e último nome',
      nationalIdentityDocument: 'DNI / NIF',
      percentage: 'porcentagem',
      editTenure: 'editar posse',
      addTenure: 'adicionar posse',
      deleteMessage: 'Tem certeza que deseja excluir este mandato?',
      tenurePorcentageMessage: 'A soma das percentagens deve ser 100%',
      requiredField: 'campos obrigatórios para preencher estão faltando',
      tenuresLength: 'necessidade de adicionar mandatos',
      tenureNif: 'DNI/NIF',
    },

    CHECKS: {
      assetChecksDataSource: 'use dados gerais do relatório',
      MinimumChecks: 'verificações mínimas',
      ChecksRelatedToAttachedDocumentation: 'verificações sobre a documentação anexada',
    },

    CONDITIONING_FACTORS: {
      conditions: 'condições',
      warnings: 'avisos',
      observations: 'observações',
      conditionalType: 'Tipo de condicionador',
      warningType: 'Tipo de aviso',
    },

    TERRAIN_DESCRIPTION_AND_SURFACES: {
      terrainSurfaces: 'superfícies de terreno',
      landInfrastructures: 'infraestruturas terrestres',
      cadastralSurfaceTotalPlotSurface: 'registro ({{surfaceUnit}})',
      registeredSurfaceTotalPlotSurface: 'cadastral ({{surfaceUnit}})',
      checkSurfaceTotalPlotSurface: 'verificado ({{surfaceUnit}})',
      terrenoUse: 'grau do solo',
      urbanizationState: 'estado de urbanização',
      morfologia: 'morfologia',
      topografia: 'topografia',
      observations: 'observações',
      withPaving: 'pavimentação',
      withStreetLighting: 'iluminação pública',
      withWaterSupply: 'abastecimento de água',
      withSanitationNetwork: 'rede de saneamento',
      withTelephoneNetwork: 'rede telefônica',
      withPipedGasNetwork: 'rede de gás centralizada',
      infrastructureObservations: 'observações',
      terrainDescription: 'Descripción del terreno',
    },

    TERRAIN_FEATURES: {
      pendingInfraestructure: 'Infraestrutura pendente',
      workDonePercentage: 'Porcentagem de trabalho realizado',
      currentInfraestructure: 'infraestrutura existente',
      urbanizationCostsAndPendingCharges: 'custos de urbanização e encargos pendentes',
      existingEdifications: 'edifícios existentes',
      surfaceToDemolish: 'superfície para demolir ({{surfaceUnit}})',
      acessibilidade: 'acessibilidade',
      groundLevel: 'Ground Level (Normativa ECO / 805/2003)',
      planningDevelopment: 'desenvolvimento de abordagem',
      managementSystem: 'sistema de gerenciamento',
      managementPhase: 'fases de gerenciamento',
      urbanCoreProximity: 'proximidade com o núcleo urbano',
      productToDevelopTypes: 'produto a ser desenvolvido',
      percentOfValuedArea: 'porcentagem do escopo avaliado',
      terrainInformationDataSource: 'fonte de dados',
      exploitingAboveGround: 'Use acima do grau ({{surfaceUnit}})',
    },
    EXCHANGES: {
      list: 'Listado de oportunidades',
      assignToMe: '¡Asígnamela!',
    },

    REPORT_VISIT_ASSET_SURFACES_FACILITIES_STAYS: {
      surfaces: 'superfície',
      facilities: 'instalações',
      stays: 'permanece',
      totalUsefulSurface: 'útil ({{surfaceUnit}})',
      totalBuiltSurfaceWithoutCommonSurfaces: 'construído sem comuns ({{surfaceUnit}})',
      totalBuiltSurfaceWithCommonSurfaces: 'construído com comuns ({{surfaceUnit}})',
    },

    REPORT_VISIT_ASSET_BUILDING_INFO: {
      localityEnvironmentAndMarket: 'locality, environment and market',
      buildingFeatures: 'características de construção',
      environmentPersonalOpinion: 'parecer técnico sobre o meio ambiente da propriedade',
      mainElements_Structure: 'estrutura',
      mainElements_Deck: 'deck',
      mainElements_Enclosure: 'cercos',
      mainElements_Foundation: 'Foundation',
      constructionYear: 'ano de construção',
      lastRehabilitationYear: 'ano de reabilitação',
      totalPlotSurface: 'sup. enredo',
      qualityOfBuilding: 'qualidade de edifícios',
      conservationState: 'estado de conservação',
      buildingUse: 'use',
      totalFloorsBellowGround: 'Não. plantas abaixo do grau',
      totalFloorsAboveGround: 'Não. plantas acima do solo',
      numberOfBuildingAccesses: 'Não. acessos ao edifício',
      streetCategory: 'categoria da rua',
      surveillance: 'vigilância',
      hasSportsZone: 'zona de esportes',
      hasSwimmingPool: 'pool',
      hasGreenArea: 'áreas verdes',
      hasElevator: 'elevador',
      observations: 'observações',
      requiredField: 'campos obrigatórios para preencher estão faltando',
    },

    INPUT_FILE: {
      errorTitle: 'Erro',
      errorMessage: 'Formato não suportado',
    },
    BooleanQuestionValues_false: 'No',
    BooleanQuestionValues_true: 'Sí',
    REQUEST_FILTERS: {
      id: 'Identificador',
      containsOwnerFullName: 'Nombre cliente',
      containsOwnerNationalIdentityDocument: 'NIF/CIF cliente',
    },
    DEBUG: {
      confirm: 'Confirme',
      setDev: 'Definir DEV',
      setQA: 'Definir QA',
      setPROD: 'Set PROD',
      setPreset: 'Ou defina um ponto final predefinido:',
      setEndpoint: 'Definir ponto final',
      setCustomEndpoint: 'Set custom endpoint:',
      enpointAdress: 'Endpoint address:',
      cancel: 'Cancelar',
    },

    COMPARABLES_FILTERS: {
      workflowItemAssetId: 'Record number',
      offerType: 'Offer type',
      addedMinDateTime: 'Added date',
      addedMaxDateTime: 'Added date',
      bathroomCount: 'Bathroom count',
      bedroomCount: 'Room count',

      hasHotWater: 'Hot water',
      hasHeating: 'Heating',
      hasAirConditioning: 'Air conditioning',
      hasGas: 'gas',
      hasPlumbingAndSanitaryEquipment: 'Plumbing and sanitary equipment',
      hasIntercom: 'pintercom',
      hasSecurity: 'security',
      hasPhone: 'phone',
      hasSanitation: 'sanitation',
      hasSwimmingPool: 'Swimming pool',
      hasSportsZone: 'Sports zone',
      hasGreenArea: 'Green area',
      hasElevator: 'Elevator',
      hasPool: 'pool',

      maxValorationTotalAdoptedSurface: 'Max surface',
      minValorationTotalAdoptedSurface: 'Min surface',
    },
    VALUATION_RESUME: {
      version: 'Versão',
      elemento: 'Elemento',
      unitValue: 'Valor unitário',
      totalValue: 'Valor total {{currency}}',
      subtotal: 'Subtotal',
      total: 'Total',
    },

    GROUP_VALUATE_isVPO: 'É VPO',
    GROUP_VALUATE_protected: 'Protegido',
    GROUP_VALUATE_free: 'Grátis',
    CLUSTER_TERRAIN: {
      addBuildingHypothesis: 'Adicionar hipótese',
      editParmaters: 'editar parâmetros',
      riskPremiumPercent: 'risk premium',
      developerProfitPercent: 'lucro do desenvolvedor (%)',
      valorationUnitValue: 'valor de mercado',
      weight: 'peso',
      repercussion: 'repercussão',
      editBuildingHypothesis: 'Editar hipótese',
      editIncome: 'Editar receita',
      editExpense: 'Editar despesas',
      maxPercentage: 'entre 0-100%',
      updatedIncome: 'Lucro atualizados',
      updatedExpenses: 'Despesas atualizados',
      totals: 'Totais',
      buildingHypothesisValuation: 'Avaliação de hipóteses',
      valuationMethod: 'Método de avaliação',
      countBuildingElements: 'Não. elementos para construir ',
      observations: 'Observações',
      calculationMethod: 'Cálculo do método',
      elementSurface: 'Estimativa da superfície do elemento ({{surfaceUnit}})',
      valorationUnitValueProposed: 'Proposta de valor ({{currency_per_surfaceUnit}})',
      terrainMethodChange: 'Alterar o método de avaliação apagará todos os itens criados a partir da avaliação anterior. Prosseguir? ',
      increaseInExternalFinancing: 'Aumentar o financiamento externo (%)',
      updateRate: 'Taxa de atualização (%)',
      monthlyRate: 'Tasa act. Mensual eq.',
      yearly: 'Anual',
      biannual: 'Mensal',
      monthly: 'Mensal',
      increaseInExternalFinancingObservations: 'Justificativa de valor',
      groupBy: 'Agrupar por',
      byPeriods: 'por períodos',
      assignValueToAll: 'Atribuir valor a todos',
      netExpensesUpdated: 'Expenses Act.',
      total: 'Total',
      atualizado: 'Atualizado',
      totalUpdated: 'Total Actualizado',
      totalIncomesExpensesDiff: 'TOTAL (I-G)',
      assigned: 'Atribuído',
      assignedTotalValue: 'Atribuído ({{currency}})',
      calculateValorationValue: 'Cálculo do valor de mercado',
    },

    BUILDING_STATE: {
      observations: 'Observaciones',
      adoptedSurface_2: 'Superficie adoptada',
      constructionCostsTotalValue: 'Coste de construcción  {{currency}}',
      recalculateWarning: 'Recuerda que después de recalcular debes revisar el reparto de activos y guardar los cambios.',
      recalculateClusterWarning: 'Lembre-se que após recalcular você deve salvar as alterações.',
      recalculateBeforeContinue: 'Es necesario recalcular antes de poder editar el formulario',
      distribution: 'Distribución',
    },

    surfaceCommons: 'Superficie construida con comunes',
    surfaceUseful: 'Superficie útil',
    surfaceWithoutCommons: 'Superficie construida sin comunes',
    cadastral: 'Catastral',
    checked: 'Comprobada',
    registered: 'Registral',

    VALIDATION_MODAL: {
      bodyText: 'Declaro ter lido e compreendido os artigos relativos às obrigações de sigilo e incompatibilidades dos profissionais a que se referem os artigos 11 e 13 do RD 775/1997, bem como as <a href="https://dev-migration.iv.solutions/files/users/IV_Reglamento_Interno_de_Conducta_2020.pdf">normas de conduta internas</a>, aceitando-se ambos para o desempenho de minha atividade profissional no Instituto de Valoraciones SA. <hr> Comprometo-me a informar o Instituto de Valoraciones de qualquer conflito de interesses decorrente da execução do meu trabalho e a manter atualizadas as minhas incompatibilidades.',
      button: 'EU CONCORDO',
    },

    CHANGE_TEMPORAL_PASSWORD_recoverMessage: 'Sua senha atual é temporária, por razões de segurança deve ser alterada',
    ASSETS_totalPlotSurface: 'Superfície de parcela ({{surfaceUnit}})',

    numberAbbreviation: 'Nº',
    currentValuationAbbreviation: 'Actual',
    futureValuationAbbreviation: 'H.E.T.',
    visit: 'Visita',
    incidentAbbreviation: 'INC',

    confirmDeleteTitle: 'Confirmar eliminar',
    confirmDeleteMessage: '¿Está seguro de querer eliminar esta valoración?',
    buildingElementChangeValuationMethodMessage: 'Al cambiar el método de valoración se eliminará el anterior método usado',
    noMoreInfo: 'No hay más información',

    REA: {
      maximumLegalDividedByCostMethodSurface: 'Este valor viene dado de el valor máximo legal dividido entre la superficie adoptada en el método de coste',
      surfaceInCostMethod: 'Superficie del método de coste: ',
    },

    EXCHANGE_TOUR: {
      welcome: '¡Bienvenido!',
      configureMaxDistance: ' Empieza configurando la distancia máxima de la que quieres recibir oportunidades',
      preferencesSaved: 'Preferencias guardadas correctamente',
      assetAddress: 'Dirección del activo',
    },
    EXCHANGE: {
      assignAppraisalInExchangeMessage: 'Con la asignación estás asumiendo la ejecución de la valoración por los honorarios y en el plazo indicado.',
      successfulAssigned: 'Asignada correctamente',
      errorAssigned: 'Ha ocurrido un error, inténtelo de nuevo.',
    },

    invoice: 'Factura',
    TOUR: {
      exitTour: 'Pular tour',
      canChangePreferences: 'Lembre-se que você sempre pode alterar suas preferências em PROFILE',
      canFilterExchanges: 'Você pode filtrar oportunidades por data e taxa',
      exchangeMoreInfo: 'Tem interesse em saber mais sobre a oportunidade?',
      clickInCard: 'Clique no cartão',
      hurryUpAndAssign: '...ou se tudo couber, apresse-se e atribua!',
      continueWithYourWork: 'E continue com o processo de trabalho normal',
    },

    CANDEACTIVATE: {
      pendingChanges: 'Existem alterações pendentes',
      pendingChangesMessage: 'Você tem alterações pendentes para confirmar. Tem certeza que deseja sair desta página?',
      yesWithoutSaving: 'Sim, sem salvar',
      yesSaving: 'Sim, salvando',
    },
    WORKPLAN_everyAssetMustHaveAValidSurface: 'Todos os ativos devem ter pelo menos uma superfície com valor',
    EXCHANGE_errorMessage: 'Ocorreu um erro, tente novamente.',
    EXCHANGE_executionMessage: 'Com a atribuição assume a execução da avaliação pelas taxas e no prazo indicado.',
    EXCHANGE_assignedRight: 'Atribuído corretamente',
    COST_METHOD_editFieldsInCurrentValoration: 'O resto dos campos só podem ser editados na guia Avaliação atual',
    VALUATION_LIST_residualDynamicInfo: 'O método residual dinâmico será adicionado a partir do capítulo Estado do trabalho',

    VALUATION_LIST_residualDynamicDeleteMessage: 'Este método só pode ser removido removendo o método residual dinâmico de dados comum correspondente na guia Status do trabalho',

    CLUSTER: {
      clusterData: 'Datos agrupación',
      valuations: 'Valoraciones',
      assets: 'Unidades',
      totalValue: 'Valoración total',
      valuationsSummary: 'Desglose de valoraciones',
      allValuations: 'Todas las valoraciones',
      canNotValuate: 'Este ativo não pode ser avaliado porque pertence a um pool.',
      assetNotHasSurfaces: 'Es necesario añadir las superficies en el apartado de Superficies del activo.',
      surfacesError: 'Faltan superficies en los activos por seleccionar.',
      valuationSurfaceSelectedMessage: 'A superfície selecionada pode ser modificada em unidades',
      valuationSurfaceTitle: 'Surface',
      necessarySaveCluster: 'Salvar é necessário para recalcular a superfície de agrupamento.',
      necessarySaveClusterValuationsStep: 'Salvar em unidades é necessário para recalcular a superfície do cluster.',
      addAsset: 'Adicionar ativo',
      removeAssetWithParentOrChildrenConfirm: 'Remover esta unidade irá remover as unidades vinculadas a ela. Retirar?',
      removeAssetConfirm: 'Você vai remover esta unidade. Retirar?',
      selectClusterType: 'Seleccionar tipo de agrupación:',
      selectClusterAssets: 'Selecionar ativos de cluster',
      minClusterAssets: '* O cluster deve consistir em pelo menos dois ativos',
      selectableAssets: 'selectable',
      noSelectableAssets: 'não selecionável',
      noItemsSection: 'Não há itens',
      noItemsToSelect: 'Não há itens que possam fazer parte do agrupamento. Se você considerar que tem um ativo que precisa agrupar, pode verificar se há um erro na parte de ativos não selecionáveis. ',
      isEconomicExploit: 'Exploração econômica',
      isNotEconomicExploit: 'Sem exploração econômica',
    },

    COST_METHOD_isUnfinishedMessage: 'La superficie adoptada solo podrá ser modificada en el capitulo de Estado de la Obra.',
    COST_METHOD_recalulateWorkPlan: 'É necessário recalcular o estado da obra e atribuir uma superfície a este ativo.',
    COST_METHOD_totalAdoptedSurface: 'É necessário definir as superfícies adotadas no capítulo Status do Trabalho.',
    CLUSTER_DETAIL_canNotRemoveAssets: 'Incapaz de remover. Deve haver pelo menos duas unidades por agrupamento ',

    REPORT_ASSET_report_structure_hasChildrenWithErrors_error: 'É necessário rever os erros pertencentes aos anexos para poder agrupar.',
    cashflows_income_imputed: 'Os Fluxos de Caixa Atribuídos mostram os valores totais de Receitas e Despesas geradas pela Exploração Econômica sem aplicação do percentual relativo à Receita imputada ao imóvel.',

    CLUSTERS_canNotChangeUseToTerrain: 'Este ativo pertence a um cluster. Se você quiser convertê-lo em terreno, é necessário removê-lo desse agrupamento ',
    EX_economicDataInfoModalTitle: 'Informações de dados econômicos',

    last_orders_empty: 'Olá {{user}}! Você não tem novos pedidos, se você perder algum, verifique o resto dos estados: Visita pendente, Envio pendente...',
  },
};
