import { Component, Input, OnInit } from '@angular/core';
import { BaseFormMobileComponent } from '../../../base-components';
import { RangeModel } from '../form-range.types';

@Component({
  selector: 'ui-mobile-form-range',
  templateUrl: './form-range.component.html',
  styleUrls: ['./form-range.component.scss'],
})
export class FormRangeComponent extends BaseFormMobileComponent implements OnInit {
  @Input() range: RangeModel;
}
