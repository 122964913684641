import { Component, Input } from '@angular/core';
import { InfoAlignment, InfoTypes } from '@foxeet/domain';

@Component({
  selector: 'ui-dynamic-info-modal',
  templateUrl: './dynamic-info-modal.html',
  styleUrls: ['./dynamic-info-modal.scss'],
})
export class DynamicInfoModalComponent {
  @Input() title = null;
  @Input() message = null;
  @Input() infoType: InfoTypes = InfoTypes.simpleMessage;
  @Input() alignment: InfoAlignment = InfoAlignment.default;

  public InfoTypes = InfoTypes;
  public InfoAlignment = InfoAlignment;
}
