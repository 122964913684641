import { Injectable } from '@angular/core';
import { AbstractControl, AbstractControlOptions, AsyncValidatorFn, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isNil, isNilOrEmptyString } from '../functions';
import { of } from 'rxjs';
import { FormModel, OrNull } from '@foxeet/domain';

@Injectable({ providedIn: 'root' })
export class FormService {
  constructor(private _formBuilder: FormBuilder) {}

  /**
   *
   * @param config data form model
   * @param abstractControlOptions
   * @returns form group according AssetTabModelMassive (id, dataSource and data which is the real tab model)
   */
  createAssetTabMassiveForm(config: FormModel[] = [], abstractControlOptions: OrNull<AbstractControlOptions> = null): FormGroup {
    return this._formBuilder.group({
      parentTempId: null,
      tempId: null,
      reportId: null,
      id: null,
      data: this.createFormGroup(config, abstractControlOptions),
      dataSource: null,
    });
  }

  /**
   *
   * @param config data form model
   * @param abstractControlOptions
   * @returns form group according AssetTabModel (id, dataSource and data which is the real tab model)
   */
  createAssetTabForm(config: FormModel[] = [], abstractControlOptions: OrNull<AbstractControlOptions> = null): FormGroup {
    return this._formBuilder.group({
      id: null,
      data: this.createFormGroup(config, abstractControlOptions),
      dataSource: null,
    });
  }

  /**
   *
   * @param config data form model
   * @param abstractControlOptions
   * @returns form group according AssetTabModel (id, dataSource and data which is the real tab model)
   */
  createValuationTabForm(config: FormModel[] = [], abstractControlOptions: OrNull<AbstractControlOptions> = null): FormGroup {
    return this._formBuilder.group({
      parentTempId: null,
      tempId: null,
      reportId: null,
      id: null,
      valorationData: this.createFormGroup(config, abstractControlOptions),
      massiveState: null,
      hasChanges: null,
      reportsUniqueIncrementalId: null,
    });
  }

  /**
   * @todo review when readonly is implemented. Not necessary? This comment is in appraisal-new component too and here in the line 65.
   */
  orderRequestValidationError(controlName: string, errors?: ValidationErrors | null): AsyncValidatorFn {
    return (formGroup: AbstractControl) => {
      let hasError = false;
      const error: ValidationErrors = { required: true };
      const control = formGroup.get(controlName);
      if (isNilOrEmptyString(control?.value) && !control?.hasError('required')) {
        hasError = true;
        control?.setErrors(error);
      }
      return !hasError ? of(null) : of({ ...errors, ...error });
    };
  }

  createFormGroup(config: FormModel[] = [], abstractControlOptions: AbstractControlOptions | null = null, initialValues = {}, defaultDisabled = false): FormGroup {
    const formGroup = this._formBuilder.group(
      config.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.name]: this.getFormElement(curr, initialValues, curr.defaultDisabled ?? defaultDisabled),
        }),
        {},
      ),
      abstractControlOptions,
    );
    if (defaultDisabled) formGroup.disable();

    /**
     * @todo review when readonly is implemented. Not necessary?. This comment is in appraisal-new component too and here in the line 36.
     */
    // const requiredFields = config.filter((el) => el.validators.includes(Validators.required)).map((el) => el.name);
    // formGroup.setAsyncValidators(requiredFields.map((requiredField) => this.orderRequestValidationError(requiredField, formGroup.errors)));

    return formGroup;
  }

  getFormElement(curr: FormModel, initialValues: any, defaultDisabled: boolean) {
    let element;
    if (curr.children) {
      let arrayFormGroup = [];
      if (initialValues && initialValues[curr.name]?.length) {
        arrayFormGroup = initialValues[curr.name].map((group) => this.createFormGroup(curr.children, null, group));
      }
      element = this._formBuilder.array(arrayFormGroup);
    } else if (curr.subGroup) {
      element = this.createFormGroup(curr.subGroup, null, initialValues ? initialValues[curr.name] : {});
    } else {
      element = this._formBuilder.control(
        {
          value: initialValues && !isNil(initialValues[curr.name]) ? initialValues[curr.name] : !isNil(curr.defaultValue) ? curr.defaultValue : null,
          disabled: defaultDisabled,
        },
        curr.validators,
        curr.asyncValidatorFn,
      );
    }
    return element;
  }

  public mapArrayToFormArrayRecursive(array: any[], formArray: FormArray, formModel?: FormModel[]) {
    formArray.clear({ emitEvent: false });
    array.forEach((el) => {
      const formGroup = formModel ? this.createFormGroup(formModel, null, el) : this._formBuilder.group(el);
      formArray.push(formGroup, { emitEvent: false });
      this.patchValueWithArrays(formGroup, el, formModel);
    });
  }

  public patchValueWithArrays(formGroup: FormGroup, data: any, formModel?: FormModel[], emitEvent?: boolean) {
    if (formGroup) {
      Object.keys(data).forEach((key) => {
        if (Object.keys(formGroup?.controls).includes(key) && Array.isArray(data[key])) {
          this.checkAndAddFormArrayIfNotExist(formGroup, key);
          if (formGroup.get(key) instanceof FormArray) {
            this.mapArrayToFormArrayRecursive(data[key], formGroup.get(key) as FormArray, formModel ? formModel.find((el) => el.name === key)?.children : formModel);
          }
        }
      });

      if (isNil(emitEvent)) emitEvent = true;
      formGroup.patchValue(data, { emitEvent });
    }
  }

  public checkAndAddFormArrayIfNotExist(formGroup: FormGroup, key: string) {
    const formArray = formGroup.get(key) as FormArray;
    if (isNil(formArray)) {
      formGroup.addControl(key, this._formBuilder.array([]));
    }
  }
}
