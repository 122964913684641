import { Component, Inject, OnDestroy } from '@angular/core';
import { DynamicConfigLoaderService } from '@foxeet/utils-modules';
import { ModalController } from '@ionic/angular';
import { BACK_CONFIG, BackConfig } from '@foxeet/data-access';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mcore-debug-modal',
  templateUrl: './debug-modal.component.html',
  styleUrls: ['./debug-modal.component.scss'],
})
export class DebugModalComponent implements OnDestroy {
  private subs$ = new Subscription();

  public endpointInput = 'http://192.168.10.10:55044';
  public environmentURL: string;

  constructor(private modalController: ModalController, private _dynamicConfigLoaderService: DynamicConfigLoaderService, @Inject(BACK_CONFIG) private backConfig: BackConfig) {
    this.environmentURL = this.backConfig.baseUrl.value;
  }

  public ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }

  valueChanges(value) {
    this.endpointInput = value;
  }

  setCustomEndpoint() {
    this.environmentURL = this.endpointInput;
  }

  setDevEndpoint() {
    this.environmentURL = this._dynamicConfigLoaderService.Options.environment.devEndpoint;
  }

  setQAEndpoint() {
    this.environmentURL = this._dynamicConfigLoaderService.Options.environment.qaEndpoint;
  }

  setProdEndpoint() {
    this.environmentURL = this._dynamicConfigLoaderService.Options.environment.prodEndpoint;
  }

  confirm() {
    this._dynamicConfigLoaderService.Options.environment.defaultEndpoint = this.environmentURL;
    this.backConfig.baseUrl.next(this.environmentURL);
    this.modalController.dismiss(true);
  }

  closeModal() {
    this.modalController.dismiss();
  }
}
