import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CurrencyEnumModel, EnumsModel } from '@foxeet/domain';
import { UnsuscriptionHandler } from '@foxeet/utils/components';
import { ModalController, NavParams } from '@ionic/angular';

export interface LanguageEnumModel extends EnumsModel {
  cultureCodeLanguage: string;
}

@Component({
  selector: 'mcore-internationalization-modal',
  templateUrl: './internationalization-modal.component.html',
  styleUrls: ['./internationalization-modal.component.scss'],
})
export class InternationalizationModalComponent extends UnsuscriptionHandler {
  public form: FormGroup;
  public segmentActive = 'language';

  public languages: LanguageEnumModel[] = [];
  public currencies: CurrencyEnumModel[] = [];
  public showWarning = false;

  constructor(private _modaController: ModalController, private _navParams: NavParams) {
    super();
    this.form = new FormGroup({
      cultureCodeLanguage: new FormControl(this._navParams.data.cultureCodeLanguage, Validators.required),
      cultureCodeCurrency: new FormControl(this._navParams.data.cultureCodeCurrency, Validators.required),
    });

    this.languages = this._navParams.data.languages;
    this.currencies = this._navParams.data.currencies;

    this.segmentActive = this.languages && this.languages.length ? 'language' : 'currency';
    this.form.get('cultureCodeCurrency').valueChanges.subscribe((value) => {
      if (value) {
        this.showWarning = true;
      }
    });
  }

  closeModal() {
    this._modaController.dismiss();
  }

  changeSegment($event) {
    this.segmentActive = $event.detail.value;
  }

  setLanguage(language: LanguageEnumModel) {
    this.form.get('cultureCodeLanguage').setValue(language.cultureCodeLanguage);
  }

  setCurrency(currency: CurrencyEnumModel) {
    this.form.get('cultureCodeCurrency').setValue(currency.cultureCodeCurrency);
  }

  save() {
    const { cultureCodeCurrency } = this.form.getRawValue();
    const lang = this.languages.find((res) => res.cultureCodeLanguage === this.form.get('cultureCodeLanguage').value).cultureCodeLanguage;
    this._modaController.dismiss({ cultureCodeCurrency, cultureCodeLanguage: lang });
  }

  selectedLanguagesComparation(item: LanguageEnumModel): boolean {
    return item.cultureCodeLanguage === this.form.get('cultureCodeLanguage').value || item.value === this.form.get('cultureCodeLanguage').value;
  }
}
