<ion-content>
  <ion-grid class="grid">
    <ion-row class="form-wrapper ion-justify-content-center">
      <ion-col size="12" class="logo-wrapper">
        <ion-img class="logo" src="assets/logo-1.png"></ion-img>
      </ion-col>

      <ion-col size-xs="12" size-sm="8" size-md="6" size-lg="4">
        <ion-card>
          <ion-card-content>
            <swiper #swiper [config]="config">
              <ng-template swiperSlide *ngIf="!isRestorePassword">
                <mcore-form-login [form]="loginForm" (login)="checkLogin()"></mcore-form-login>

                <ion-row class="ion-justify-content-center link-wrapper">
                  <ion-col size="12">
                    <p class="center">
                      <span class="link" id="AT_recover_password" (click)="slideNext()">{{ 'LOGIN_recoverPassword' | translate | capitalize }}</span>
                    </p>
                  </ion-col>
                </ion-row>
              </ng-template>

              <ng-template swiperSlide *ngIf="!isRestorePassword">
                <ion-row>
                  <ion-col size="12">
                    <p class="small">
                      {{ 'LOGIN_recoverMessage' | translate }}
                    </p>
                  </ion-col>
                </ion-row>

                <mcore-form-recover-password [form]="recoverPasswordForm" (recoverPassword)="checkRecoverPassword()"></mcore-form-recover-password>

                <ion-row class="ion-justify-content-center link-wrapper">
                  <ion-col size="12">
                    <p>
                      <span class="link" (click)="slidePrev()">{{ 'LOGIN_backToLogin' | translate | capitalize }}</span>
                    </p>
                  </ion-col>
                </ion-row>
              </ng-template>

              <ng-template swiperSlide>
                <mcore-password-restore></mcore-password-restore>
              </ng-template>
            </swiper>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid class="footer no-padding-vert">
    <ion-row class="form ion-justify-content-between ion-align-items-end">
      <ion-col class="language-seleector-wrapper form" size-xs="5" size-sm="4" size-md="4" size-lg="3">
        <mcore-language-selector [defaultLanguage]="defaultLanguage" [languageList]="languageList"></mcore-language-selector>
      </ion-col>

      <ion-col class="legend-wrapper" size="auto">
        <p class="small">{{ year }} © {{ appName }} · v{{ appVersion }}</p>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
