<ion-item
  [ngClass]="{
    error: showValidationMessages && control,
    'non-opacity': readonly,
    suggested: suggested && (control?.value === null || control?.value === undefined || control?.value === '')
  }"
  (click)="openModal($event)"
>
  <ion-label [position]="labelPosition" *ngIf="label">{{ label | translate | capitalize }}</ion-label>
  <ion-label [position]="labelPosition" *ngIf="showLabelInSurfaceSelected && selectedItem"
    ><b>{{ selectedItem?.group | translate }}</b> - {{ selectedItem?.label | translate }}
  </ion-label>

  <p *ngIf="showValue">{{ selectedItem?.selectedLabel || (placeholder | translate) }}</p>
  <ion-input
    hidden
    type="text"
    [formControl]="control"
    [placeholder]="placeholder ?? undefined"
    (ionBlur)="control?.updateValueAndValidity()"
    [disabled]="!options?.length"
  ></ion-input>
  <ion-icon name="caret-down-outline" slot="end" color="medium"></ion-icon>
</ion-item>

<ui-mobile-form-validation-messages *ngIf="showValidationMessages && control" [control]="control"></ui-mobile-form-validation-messages>
