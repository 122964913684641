<ion-item
  class="input-toggle"
  [ngClass]="{
    error: showValidationMessages && control,
    'non-opacity': readonly
  }"
>
  <ion-toggle justify="start" labelPlacement="end" [id]="componentId" color="primary" [formControl]="control" (ionBlur)="control.updateValueAndValidity()" [disabled]="readonly">
    {{ label | translate | uppercase }}
  </ion-toggle>
</ion-item>

<ui-mobile-form-validation-messages *ngIf="showValidationMessages" [control]="control"></ui-mobile-form-validation-messages>
