import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { EventType } from '@foxeet/domain';
import { PATHS } from '@foxeet/n-core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'mcore-calendar-info',
  templateUrl: './calendar-info.component.html',
  styleUrls: ['./calendar-info.component.scss'],
})
export class MobileCalendarInfoComponent {
  public eventType = EventType;

  @Input() item;

  constructor(private _modalController: ModalController, private _router: Router) {}

  goToAppraisal(event) {
    this.close();
    this._router.navigateByUrl(`${PATHS.ORDERS}/${event.appraisalId}/info`);
  }

  close() {
    this._modalController.dismiss();
  }
}
