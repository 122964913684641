export enum ChipType {
  WARNING,
  INFO,
  DANGER,
  DEFAULT,
  PRIMARY,
  SECONDARY,
  TERTIARY,
  DARK,
  SUCCESS,
  PRIMARY_SHADE,
  SECONDARY_TINT,
  INVERSE,

  PRIMARY_OUTLINE,
  DANGER_OUTLINE,
  REJECTED_OUTLINE,
  WARNING_OUTLINE,
  TERTIARY_SHADE,
  TERTIARY_TINT,
  DEFAULT_REVERSE,
}

export interface ChipModel {
  label?: string;
  icon?: string;
  image?: string;
  //   color?: string;
  type?: ChipType;
  filter?: number;
  clickable?: boolean;
  active?: boolean;
  link?: string;
  hide?: boolean;
  id?: string;
}

export enum ChipsCustomEnum {
  Created_PendingAssignProcessManager = 1,
  AssignedProcessManager_PendingAssignAppraiser = 2,
  AssignedAppraiser_PendingDateForVisit = 3,
  VisitDated_PendingStartVisit = 4,
  VisitStarted_PendingFinishVisit = 5,
  VisitFinished_PendingSendData = 6,
  DataSent_PendingAssignManagerTechnical = 7,
  AssignedManagerTechnical_PendingStartValidation = 8,
  ValidationStarted_PendingValidationFinished = 9,
  ValidationFinished_PendingSendToCustomer = 10,
  SentToCustomer = 11,
  // Custom
  Rejected = 12,
  EXCHANGE_TIME = 13,
  EXCHANGE_DISTANCE = 14,
  INCIDENTS_LEFT_CHIP = 15,
  INCIDENTS_RIGHT_CHIP = 16,
  APPRAISAL_PRICE = 17,
}
