import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { I18nModule, PipesModule } from '@foxeet/utils-modules';
import { ReactiveFormsModule } from '@angular/forms';
import { FormInputTextAreaComponent } from './form-input-textarea.component';
import { FormValidationMessagesModule } from '../../form-validation-messages/mobile/form-validation-messages.module';

@NgModule({
  declarations: [FormInputTextAreaComponent],
  imports: [CommonModule, IonicModule, FormValidationMessagesModule, I18nModule.forChild(), ReactiveFormsModule, PipesModule],
  exports: [FormInputTextAreaComponent],
})
export class FormInputTextareaModule {}
