<ion-item
  lines="none"
  [ngClass]="{
    error: errors || control?.invalid,
    suggested: suggested && (control?.value === null || control?.value === undefined || control?.value === '')
  }"
>
  <div class="wrapper">
    <div
      class="switch"
      [ngClass]="{
        disabled: control?.disabled,
        center: control?.value === null,
        start: control?.value === false,
        end: control?.value === true
      }"
    >
      <div class="slider"></div>
      <div [id]="componentId + '_left'" class="false-value" (click)="control?.disabled || readonly ? $event.stopPropagation() : setValue(control, false)">
        <ion-icon class="true-icon" *ngIf="control?.value === true" name="checkmark"></ion-icon>
      </div>
      <div class="null-value" [id]="componentId + '_middle'" (click)="control?.disabled || readonly ? $event.stopPropagation() : setValue(control, null)"></div>
      <div [id]="componentId + '_right'" class="true-value" (click)="control?.disabled || readonly ? $event.stopPropagation() : setValue(control, true)">
        <ion-icon class="false-icon" *ngIf="control?.value === false" name="close"></ion-icon>
      </div>
    </div>

    <div *ngIf="label" class="label">{{ label | translate }}</div>
  </div>
</ion-item>
<ui-mobile-form-validation-messages *ngIf="showValidationMessages && control" [control]="control"></ui-mobile-form-validation-messages>
