import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from '@foxeet/data-access';
import { PASSWORD } from '@foxeet/utils/forms';
import { checkIfFieldsAreEqual, customRegexValidator } from '@foxeet/utils/validators';
import { PATHS } from '@foxeet/n-core';
import { ToastService } from '@foxeet/utils/services/toast.service';

@Component({
  selector: 'mcore-change-temporal-password',
  templateUrl: './change-temporal-password.component.html',
  styleUrls: ['./change-temporal-password.component.scss'],
})
export class ChangeTemporalPasswordComponent {
  public form: FormGroup = new FormGroup({});

  constructor(private _accountService: AccountService, private _toastService: ToastService, private _router: Router) {
    this._initForm();
  }

  private _initForm(): void {
    this.form = new FormGroup(
      {
        password: new FormControl(null, [Validators.required, customRegexValidator(PASSWORD.pattern, PASSWORD.errorKey)]),
        confirmPassword: new FormControl(null, Validators.required),
      },
      checkIfFieldsAreEqual('password', 'confirmPassword'),
    );
  }

  public submit() {
    if (this.form.valid) {
      this._accountService.changeTemporalPassword(this.form.get('password').value, this.form.get('confirmPassword').value).subscribe(
        (result) => {
          if (result.ok) {
            this._router.navigate([`${PATHS.APPRAISALS}`]);
          }
        },
        (e) => {
          this._toastService.error(e.error.title, e.error.detail);
        },
      );
    }
  }
}
