import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { loginControls } from '../../auth/domain/auth.config';

@Component({
  selector: 'mcore-form-recover-password',
  templateUrl: './form-recover-password.component.html',
  styleUrls: ['./form-recover-password.component.scss'],
})
export class FormRecoverPasswordComponent {
  public formNames = loginControls;

  @Input() form: FormGroup;
  @Output() recoverPassword: EventEmitter<boolean> = new EventEmitter(false);

  submitRecoverPassword() {
    this.recoverPassword.next(true);
  }
}
