<ion-list id="inbox-list">
  <div *ngFor="let item of menu; let i = index">
    <ng-container *ngIf="!item?.children?.length">
      <ion-menu-toggle auto-hide="false">
        <mcore-menu-item
          *ngIf="!item.authorityRoute || (item.authorityRoute | editableIfHasAuthorities)"
          [item]="item"
          [selectedPath]="selectedPath"
          (itemClicked)="item?.url ? onClickItemWithRoute(item) : item.callback()"
        ></mcore-menu-item>
      </ion-menu-toggle>
    </ng-container>

    <div *ngIf="!!item?.children?.length" [ngClass]="{ 'separation-section': i > 0 }">
      <mcore-menu-item
        *ngIf="!item.authorityRoute || (item.authorityRoute | editableIfHasAuthorities)"
        [item]="item"
        [selectedPath]="selectedPath"
        itemClass="parent"
      ></mcore-menu-item>

      <ion-list class="children-list" [hidden]="!item.opened">
        <ng-container *ngFor="let subitem of item.children; let j = index">
          <ion-menu-toggle *ngIf="!subitem.authorityRoute || (subitem.authorityRoute | editableIfHasAuthorities)" auto-hide="false">
            <mcore-menu-item [item]="subitem" [selectedPath]="selectedPath" (itemClicked)="subitem?.url ? onClickItemWithRoute(subitem) : subitem.callback()"></mcore-menu-item>
          </ion-menu-toggle>
        </ng-container>
      </ion-list>
    </div>
  </div>
</ion-list>
