import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { I18nModule, PipesModule, DirectivesModule } from '@foxeet/utils-modules';
import { IonicModule } from '@ionic/angular';
import { FormValidationMessagesModule } from '../../form-validation-messages/mobile/form-validation-messages.module';
import { FormInputNumberComponent } from './form-input-number.component';

@NgModule({
  declarations: [FormInputNumberComponent],
  imports: [CommonModule, IonicModule, PipesModule, ReactiveFormsModule, I18nModule.forChild(), FormValidationMessagesModule, DirectivesModule],
  exports: [FormInputNumberComponent],
})
export class FormInputNumberModule {}
