import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LanguageSelectorItem, LanguageSelectorList } from '@foxeet/domain';
import { FormService } from '@foxeet/utils/services';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

/** TODO: cambiar la forma de usar DEFAULT_LANG y LANGUAGES para pasarlo como input? */

@Component({
  selector: 'mcore-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {
  public form: FormGroup;

  public selectedLanguage: LanguageSelectorItem;
  public langValue;

  @Input() defaultLanguage: string;
  @Input() languageList: LanguageSelectorList = [];

  constructor(private _platform: Platform, private _formService: FormService, private _translateService: TranslateService) {}

  ngOnInit() {
    this.langValue = this.defaultLanguage.includes('-') ? this.defaultLanguage.split('-')[0] : this.defaultLanguage;
    const initialLangKey = this.langValue || this._translateService.getDefaultLang();

    const langFormControls = [
      {
        name: 'language',
        validators: [],
        asyncValidatorFn: [],
        defaultValue: initialLangKey,
      },
    ];

    this.form = this._formService.createFormGroup(langFormControls);
    // this._setLanguagesByPlatform();
    this.setLanguage(initialLangKey.includes('-') ? initialLangKey.split('-')[0] : initialLangKey);
  }

  public setLanguage(languageKey: string) {
    this._translateService.use(languageKey);
    /**
     * TODO: remove when migrate to tasafy language modal ?? To save language selected in back
     */
    localStorage.setItem('language', languageKey);
  }
}
