<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>

    <ion-title>{{ 'MENU_schedule' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <mcore-calendar (changeMonth)="changeCalendarMonth($event)"></mcore-calendar>
</ion-content>
