import { Component, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@foxeet/feature/auth';
import { ToastService } from '@foxeet/utils/services/toast.service';
import { UnsuscriptionHandler } from '@foxeet/utils/components';
import { DynamicConfigLoaderService } from '@foxeet/utils-modules';
import { MenuController, Platform, PopoverController } from '@ionic/angular';
import { take, takeUntil } from 'rxjs/operators';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

import { FormService } from '@foxeet/utils/services';
import { M_PATHS } from '@foxeet/n-core';
import { LanguageService } from '@foxeet/utils/services/language.service';
import { NotificationsService } from '@foxeet/utils/services/notifications.service';
import { DomSanitizer } from '@angular/platform-browser';
import { BackUrlService } from '@foxeet/data-access';
import { TranslateService } from '@ngx-translate/core';
import { LanguageSelectorList } from '@foxeet/domain';
import { InfoAlignment, InfoTypes, DynamicInfoModalComponent } from '@foxeet/ui';
import { loginControls, loginForm, recoverPaswordForm } from '../../domain/auth.config';

@Component({
  selector: 'mcore-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends UnsuscriptionHandler implements OnInit {
  private _appVersionAlertShowed = false;

  public year = new Date().getFullYear();
  public appVersion = null;
  public appName: string;

  public loginForm: FormGroup;
  public loginFormNames = loginControls;
  public recoverPasswordForm: FormGroup;

  public isRestorePassword = false;
  public defaultLanguage: string;
  public languageList: LanguageSelectorList = [];

  config: SwiperOptions = {
    slidesPerView: 1,
    initialSlide: 0,
    allowTouchMove: false,
    autoHeight: false,
    navigation: false,
    pagination: false,
    scrollbar: false,
  };

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  constructor(
    private _dynamicConfigLoaderService: DynamicConfigLoaderService,
    private _menuController: MenuController,
    private _formService: FormService,
    private _authService: AuthService,
    private _router: Router,
    private _toastService: ToastService,
    private _notifications: NotificationsService,
    private _platform: Platform,
    private _languageService: LanguageService,
    private _popoverController: PopoverController,
    private _domSanitizer: DomSanitizer,
    private _backUrlService: BackUrlService,
    private _translateService: TranslateService,
  ) {
    super();
    this._checkIfShowAppVersionAlert();

    const { environment, languagesConfig } = this._dynamicConfigLoaderService.Options;
    this.appVersion = environment.version;
    this.appName = environment.appName;
    this.defaultLanguage = languagesConfig.defaultLanguage;
    this.languageList = this._languageService.getLanguageList(languagesConfig.availableLanguages);
  }

  ngOnInit() {
    this._menuController.enable(false);
    this.loginForm = this._formService.createFormGroup(loginForm);
    this.recoverPasswordForm = this._formService.createFormGroup(recoverPaswordForm);

    this._router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.isRestorePassword = val.url.includes(M_PATHS.RESTORE);
      }
    });

    this._notifications.initPush();
  }

  ionViewWillLeave() {
    this._menuController.enable(true);
  }

  private async _showAppVersionAlert() {
    const isDevice = this._platform.platforms().includes('android') || this._platform.platforms().includes('ios');
    const popover = await this._popoverController.create({
      component: DynamicInfoModalComponent,
      backdropDismiss: false,
      componentProps: {
        title: this._translateService.instant('web_reload_app_title'),
        message: this._domSanitizer.sanitize(
          SecurityContext.HTML,
          this._translateService.instant(
            isDevice ? (this._platform.platforms().includes('android') ? 'mobile_android_reload_app_message' : 'mobile_ios_reload_app_message') : 'mobile_web_reload_app_message',
          ),
        ),
        infoType: InfoTypes.innerHTML,
        alignment: InfoAlignment.center,
      },
    });

    popover.present();
  }

  private _checkIfShowAppVersionAlert() {
    this._backUrlService.showAppVersionAlert$.pipe(takeUntil(this._componentDestroyed)).subscribe((showAlert) => {
      if (showAlert && !this._appVersionAlertShowed) {
        this._appVersionAlertShowed = true;
        this._showAppVersionAlert();
      }
    });
  }

  private _login(username, password, firebaseToken = null) {
    this._authService
      .login(username, password, firebaseToken)
      .pipe(take(1))
      .subscribe(
        (res) => {
          if (this._authService.itIsTemporalPassword()) {
            this._router.navigate([`${M_PATHS.CHANGE_TEMPORAL_PASSWORD}`]);
          } else {
            this._authService.setUserProfile();
            this._router.navigate([`${M_PATHS.APPRAISALS}`]);
          }
        },
        (e) => {
          this.loginForm.get(this.loginFormNames.password).setErrors({ wrongCredentials: true });
          if (e && e.error) this._toastService.error('error', e.error.detail);
        },
      );
  }

  public checkLogin() {
    if (this.loginForm.valid) {
      const { username, password } = this.loginForm.getRawValue();
      console.log(this._platform.platforms());

      if (this._platform.is('capacitor')) {
        this._notifications.tokenEnable.pipe(takeUntil(this._componentDestroyed)).subscribe((firebase) => {
          this._login(username, password, firebase);
        });
      } else {
        this._login(username, password);
      }
    } else {
      Object.keys(this.loginForm.controls).forEach((key) => {
        this.loginForm.controls[key].markAsTouched();
        this.loginForm.controls[key].updateValueAndValidity();
      });
    }
  }

  public checkRecoverPassword() {
    if (this.recoverPasswordForm.valid) {
      this._authService.sendMailToRestoreThePassword(this.recoverPasswordForm.controls.username.value).subscribe(
        (response) => this._toastService.success('LOGIN_emailHasBeenSend'),
        (e) => this._toastService.error(e.error.title, e.error.detail),
      );
    }
  }

  public slideNext() {
    this.swiper.swiperRef.slideNext(100);
  }

  public slidePrev() {
    this.swiper.swiperRef.slidePrev(100);
  }
}
