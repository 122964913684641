import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { I18nModule, PipesModule } from '@foxeet/utils-modules';
import { FormCalendarComponent } from './form-calendar.component';
import { FormValidationMessagesModule } from '../../form-validation-messages/mobile/form-validation-messages.module';

@NgModule({
  declarations: [FormCalendarComponent],
  imports: [CommonModule, IonicModule, FormValidationMessagesModule, ReactiveFormsModule, I18nModule.forChild(), PipesModule],
  exports: [FormCalendarComponent],
})
export class FormCalendarModule {}
