<ng-container [ngSwitch]="item.type">
  <ion-item
    *ngSwitchCase="MenuItemType.default"
    id="AT_{{ item?.id }}"
    [ngClass]="!item.customColors ? itemClass : null"
    [class.selected]="selectedPath === item?.url"
    [ngStyle]="item.customColors ? { color: item.customColors.color, backgroundColor: item.customColors.backgroundColor } : null"
    routerDirection="root"
    lines="none"
    detail="false"
    (click)="actionOnClick(item)"
  >
    <ion-img *ngIf="item?.img" slot="start" [src]="item?.img"></ion-img>
    <ion-icon *ngIf="item?.icon" slot="start" [name]="item?.icon"></ion-icon>
    <ion-label>{{ item?.title | translate | uppercase }}</ion-label>
    <ui-mobile-chip *ngIf="item?.chip" [chip]="item?.chip"></ui-mobile-chip>
    <ion-icon *ngIf="item?.iconRight" slot="end" [name]="item?.iconRight"></ion-icon>
    <ion-icon *ngIf="item?.isDropdown" class="dropdown-icon {{ item.opened ? 'rotate' : '' }}" slot="end" name="chevron-down-outline"></ion-icon>
  </ion-item>

  <ion-item *ngSwitchCase="MenuItemType.message" class="message" lines="none" detail="false">
    <ion-icon slot="start" [name]="item?.icon"></ion-icon>
    <ion-label>{{ item?.title | translate }}</ion-label>
  </ion-item>
</ng-container>
