<ion-item
  class="input-checkbox"
  [ngClass]="{
    error: showValidationMessages && control,
    'non-opacity': readonly,
    suggested: suggested && (control?.value === null || control?.value === undefined || control?.value === '')
  }"
>
  <ion-checkbox [ariaChecked]="control?.value" justify="start" labelPlacement="end" [id]="componentId" [formControl]="control" (ionChange)="focus()">
    {{ label | translate | uppercase }}
  </ion-checkbox>
</ion-item>

<ui-mobile-form-validation-messages *ngIf="showValidationMessages && control" [control]="control"></ui-mobile-form-validation-messages>
