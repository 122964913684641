import { Component } from '@angular/core';
import { BaseFormValidationMessagesComponent } from '../base-form-validation-messages.component';

@Component({
  selector: 'ui-mobile-form-validation-messages',
  templateUrl: './form-validation-messages.component.html',
  styleUrls: ['./form-validation-messages.component.scss'],
})
export class FormValidationMessagesComponent extends BaseFormValidationMessagesComponent {
  // TODO add wrongInput to @iv/core
  displayWrongInputError = (): boolean => this.generalDisplayError('wronInput');
  displayIsNotValidError = (): boolean => this.generalDisplayError('isNotValid');
}
