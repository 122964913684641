<div *ngIf="control.invalid && (control.dirty || control.touched)" class="validation-messages">
  <p *ngIf="displayRequiredError()">
    {{ 'FORM_VALIDATION_REQUIRED' | translate }}
  </p>

  <p *ngIf="displayPatternError()">
    {{ 'FORM_VALIDATION_PATTERN' | translate }}
  </p>

  <p *ngIf="displayEmailValidation()">
    {{ 'FORM_VALIDATION_EMAIL_VALIDATION_ERROR' | translate }}
  </p>

  <p *ngIf="displayEmailAltValidation()">
    {{ 'FORM_VALIDATION_EMAIL_VALIDATION_ERROR_ALT' | translate }}
  </p>

  <p *ngIf="displayBudgetNumberValidation()">
    {{ 'FORM_VALIDATION_BUDGET_NUMBER_MASK_ERROR' | translate }}
  </p>

  <p *ngIf="displayMaxLengthError()">
    {{ 'FORM_VALIDATION_MAX_LENGTH' | translate }}
    {{ getErrorValue(inputValidationErrors.MAX_LENGTH).requiredLength }}
  </p>

  <p *ngIf="displayMinLengthError()">
    {{ 'FORM_VALIDATION_MIN_LENGTH' | translate }}
    {{ getErrorValue(inputValidationErrors.MIN_LENGTH).requiredLength }}
  </p>

  <p *ngIf="displayMinInputError()">
    {{ 'FORM_VALIDATION_MIN' | translate }}
    {{ getErrorValue(inputValidationErrors.MIN).min }}
  </p>

  <p *ngIf="displayMaxInputError()">
    {{ 'FORM_VALIDATION_MAX' | translate }}
    {{ getErrorValue(inputValidationErrors.MAX).max }}
  </p>

  <p *ngIf="displayWrongInputError()">
    {{ 'FORM_VALIDATION_WRONG_INPUT' | translate }}
  </p>

  <p *ngIf="displayIsNotValidError()">
    {{ 'FORM_VALIDATION_additionHigherThan' | translate }}
  </p>

  <p *ngIf="displayPassNotMatchError()">
    {{ 'FORM_VALIDATION_PASSWORD_DOES_NOT_MATCH' | translate }}
  </p>

  <p *ngIf="displayMinAndMaxError()">
    {{ 'FORM_VALIDATION_MIN_MAX_VALUE' | translate : null : { min: control?.errors.valueRange?.min, max: control?.errors.valueRange?.max } }}
  </p>
</div>
