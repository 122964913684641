import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { BaseFormMobileComponent } from '../../../base-components';

@Component({
  selector: 'ui-mobile-form-input-textarea',
  templateUrl: './form-input-textarea.component.html',
  styleUrls: ['./form-input-textarea.component.scss'],
})
export class FormInputTextAreaComponent extends BaseFormMobileComponent implements OnInit {
  @Input() autoGrow = true;
  @Input() cssClass?: string;
  @Input() canEmitOnBlur = true;
  @Output() controlEmitter = new EventEmitter<boolean>();

  loaded = false;

  ngOnInit() {
    super.ngOnInit();

    /**
     * @description bug: Autogrow property in IonTextArea doesnt look good #21242
     * @see https://github.com/ionic-team/ionic-framework/issues/21242
     * @status Open
     */
    setTimeout(() => {
      this.loaded = true;
    }, 250);

    this.control.valueChanges.pipe(takeUntil(this._componentDestroyed)).subscribe((el) => {
      this.controlEmitter.emit(true);
    });
  }
}
