import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormCalendarMobileIcons } from '../../form-input-calendar';
import { proposedDatesControls } from '../form-proposed-dates.types';
import { transformDateToPrint } from '@foxeet/utils/functions';

@Component({
  selector: 'ui-mobile-form-proposed-dates',
  templateUrl: './form-proposed-dates.component.html',
  styleUrls: ['./form-proposed-dates.component.scss'],
})
export class FormProposedDatesComponent implements OnInit {
  FormCalendarIcons = FormCalendarMobileIcons;

  @Input() options!: any[];
  @Input() readonly!: boolean;
  @Input() clickable!: boolean;

  @Output() dateSelectedEmitter = new EventEmitter<FormGroup>();
  @Output() formEmitter = new EventEmitter<FormArray>();

  public formArray: FormArray = new FormArray([]);
  public proposedDatesControls = proposedDatesControls;
  public selected?: FormGroup;

  ngOnInit() {
    if (this.options?.length) {
      this._setVisitProposalOptions();
    }

    this.formArray.valueChanges.subscribe(() => this.formEmitter.emit(this.formArray));
  }

  public toggleValueChange(value: boolean, formGroup: FormGroup): void {
    const visitProposalUntilDateTimeControl = formGroup.get('visitProposalUntilDateTime');
    if (value && visitProposalUntilDateTimeControl) {
      visitProposalUntilDateTimeControl.clearValidators();
      visitProposalUntilDateTimeControl.updateValueAndValidity();
    } else {
      visitProposalUntilDateTimeControl?.setValidators(Validators.required);
    }
  }

  private _setVisitProposalOptions(): void {
    this.options.forEach((elem) => {
      this.formArray.push(
        new FormGroup({
          visitProposalFromDateTime: new FormControl(transformDateToPrint(elem[proposedDatesControls.from], 'YYYY-MM-DDTHH:mm:ss')),
          visitProposalUntilDateTime: new FormControl(transformDateToPrint(elem[proposedDatesControls.until], 'YYYY-MM-DDTHH:mm:ss')),
          allDay: new FormControl(elem[proposedDatesControls.allDay]),
        }),
      );
    });
  }

  public setVisitProposalForm(): void {
    const visitProposalFromDateTime = new FormControl(null, Validators.required);
    const visitProposalUntilDateTime = new FormControl(null, Validators.required);
    visitProposalFromDateTime.markAsDirty();
    visitProposalUntilDateTime.markAsDirty();
    const formGroup = new FormGroup({
      visitProposalFromDateTime,
      visitProposalUntilDateTime,
      allDay: new FormControl(false),
    });
    this.formEmitter.emit(this.formArray);
    this.formArray.push(formGroup);
  }

  removeDateProposed(index: number) {
    this.formArray.removeAt(index);
    this.formEmitter.emit(this.formArray);
  }

  public dateSelected(form: AbstractControl) {
    this.selected = form as FormGroup;
    this.dateSelectedEmitter.emit(form as FormGroup);
  }
}
