<ion-grid>
  <ion-row class="ion-justify-content-center">
    <ion-col size="12">
      <ion-avatar>
        <img [src]="user?.image ? user?.image : './assets/img/noavatar.png'" default="./assets/img/noavatar.png" />
      </ion-avatar>
    </ion-col>

    <ion-col size="12">
      <p class="title">{{ user?.title }}</p>
    </ion-col>

    <ion-col size="12" class="no-padding">
      <p class="subtitle">{{ user?.subtitle }}</p>
    </ion-col>

    <ion-col size="12" *ngIf="user?.sharingId" class="no-padding">
      <ion-row class="ion-align-items-center">
        <ion-col class="no-padding">
          <p class="code">{{ user?.sharingId }}</p>
        </ion-col>
        <ion-col size="auto">
          <ion-button size="small" class="button-icon-clear-rounded-contrast" [cdkCopyToClipboard]="user?.sharingId" (click)="copyCode()"
            ><ion-icon name="clipboard-outline" color="dark"></ion-icon
          ></ion-button>
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-row>
</ion-grid>
